import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


// Icons
import { IoIosArrowDown } from 'react-icons/io';
import { FaCalendar } from "react-icons/fa";

// Local Imports
import DSMItem from './DSMItem';


const dsmData = [];

const DSMsTable = () => {
  // Pagintion COntrols
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  
    // Retrieve DSMs of the current user
    const currentUser = JSON.parse(localStorage.getItem('user'))['user_data'];
    const accessToken = localStorage.getItem('accessToken');
    const [dsms, setDSMs] = useState([]);

    useEffect(() => {
      // console.log("Retrieving DSM!")
      const getDSMs = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/user/${currentUser.id}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          
          //console.log("Orders Data: " + response.data);
    
          if (response.status >= 200 && response.status < 300) {
            setDSMs(response.data);
          } else {
            console.error(`Error retrieving data: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error retrieving data: ${error}`);
        }
      };
    
      getDSMs();
    }, [currentUser.id, accessToken]);
  
  
  
  // CLick Filter Button
  const [useEffectTrigger, setUseEffectTRIgger] = useState(0);
  const handleFilter = () => {
    setUseEffectTRIgger(prev => prev + 1);
  };



  // Orde DropLists filter
  const [dsmNames, setDSMNames] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Date Range Filter
  const [creationStartDate, setCreationStartDate] = useState(new Date());
  const [creationEndDate, setCreationEndDate] = useState(new Date());
  const [isCreationDateickerVisible, setCreationDatePickerVisible] = useState(false);

  const toggleCreationDatePicker = () => setCreationDatePickerVisible(!isCreationDateickerVisible);
  
  // DSM Max. Product Count Values
  const[productCount, setProductCount] = useState(null);

  const handleMaxProductCount = (event) => {
    setProductCount(event.target.value);
  }


  
  // Filtration SEction controls
  const items = [
          'DSMs', 
          'ACTIVE DSMs', 
          'NonACTIVE DSMs', 
          'PAUSED DSMs'
        ];
  const [activeItem, setActiveItem] = useState('DSMs');
  const [filteredDSMs, setFilteredDSMs] = useState(dsms);
  const [creationStatus, setCeationStatus ] = useState(null);
  const [ selectedCreationStatus, setSelectedCreationStatus] = useState(null);

  const handleCreationChange = (selectedCreationStatus) => {
    setSelectedCreationStatus(selectedCreationStatus);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  useEffect(() => {
    const statusMap = {
      'DSMs': ['active', 'paused', 'non_active'],
      'ACTIVE DSMs': ['active'],
      'NonACTIVE DSMs': ['non_active'],
      'PAUSED DSMs': ['paused'],
    };
    const statusTofilterBy = statusMap[activeItem];

    const filtered = dsms.filter(dsm => statusTofilterBy.includes(dsm.dsm_status));
    setFilteredDSMs(filtered);


    const dsmNames =filtered.map(dsm => dsm.dsm_name);
    const formattedDSMNames = dsmNames.map(dsmName => ({
      value: dsmName,
      label: dsmName.toString(),
    }));
    const defaultValue = { value: 'default', label: 'All DSMS'};
    formattedDSMNames.unshift(defaultValue);
    setDSMNames(formattedDSMNames);


    const creationStatus = [
      { value: 'default', label: 'All DSMS'},
      { value: 'created', label: 'Created'},
      { value: 'invoiced', label: 'Invoiced'},
      { value: 'paid', label: 'Paid'}
    ];
    setCeationStatus(creationStatus);
    
  }, [activeItem, dsms]);

  // DSM Name Filter
  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.value === 'default') {
        setActiveItem('DSMs');
        setFilteredDSMs(dsms);
        setSelectedOption(null);
        setCreationStartDate(null);
        setCreationEndDate(null);
        setSelectedCreationStatus(null);
      } else {
        const numFilteredDSMs = filteredDSMs.filter(dsm => 
          dsm.dsm_name === selectedOption.value
        );
        setFilteredDSMs(numFilteredDSMs);
      }
    }
  }, [selectedOption, filteredDSMs, dsms]);


    // DSM Creation Status Filter
    useEffect(() => {
      if (selectedCreationStatus) {
        if (selectedCreationStatus.value === 'default') {
          setActiveItem('DSMs');
          setFilteredDSMs(dsms);
          setSelectedOption(null);
          setCreationStartDate(null);
          setCreationEndDate(null);
          setSelectedCreationStatus(null);
        } else {
          const numFilteredDSMs = filteredDSMs.filter(dsm => 
            dsm.creation_fee_status === selectedCreationStatus.value
          );
          setFilteredDSMs(numFilteredDSMs);
        }
      }
    }, [selectedCreationStatus, filteredDSMs, dsms]);


  // Creation Date Range Change
  useEffect(() => {
    if (creationStartDate === '' || creationEndDate === '') {
      const numFilteredDSMs = filteredDSMs.filter( dsm =>
        {
          const creationDate = new Date(dsm.creation_date);
          if ( creationStartDate && creationEndDate) {
            return creationDate >= creationStartDate && creationDate <= creationEndDate;
          } else if ( creationStartDate ) {
            return creationDate >= creationStartDate;
          } else if ( creationEndDate ) {
            return creationDate <= creationEndDate;
          }
          return true;
        });
        setFilteredDSMs(numFilteredDSMs);
    }
  }, [creationStartDate, creationEndDate, filteredDSMs, useEffectTrigger]);

  // DSM Max. productCount filter
  useEffect(() => {
    if (productCount) {
      const numFilteredDSMs = filteredDSMs.filter(dsm => dsm.product_count <= productCount);
      setFilteredDSMs(numFilteredDSMs);
    }
  }, [productCount, filteredDSMs, useEffectTrigger]);

  
  return (
    <>
      {/**Filtration section Orders Focus */}
      <div className="w-100 container sticky top-0 border-b-2 border-gray-200 bg-white p-2">
        <ul className="w-100  flex font-bold ">
          {items.map((item, index) => (
            <li key={index} className="flex-1">
              <a
                className={`block rounded border-b-2 border-transparent  text-center text-xs sm:text-base
                ${
                  activeItem === item 
                  ? 'font-bold text-gray-700' 
                  : 'text-gray-400'
                } 
                 hover:font-bold hover:text-gray-700`}
                onClick={() => handleItemClick(item)}
                href="#"
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Filtration section Apply filter button */}
      <div className="flex w-full flex-wrap items-center justify-between border-b-2 border-gray-300 bg-white p-3 shadow-md">
        <div className="flex w-full justify-around md:w-[40%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <Select
              className="w-full"
              options={dsmNames}
              placeholder="DSM Name"
              onChange={handleChange}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'gray-400',
                  primary: 'border',
                },
              })}
              styles={{
                placeholder: (defaultStyles) => {
                  return {
                    ...defaultStyles,
                    color: 'gray-600',
                    fontStyle: 'italic',
                    fontWeight: 'semibold',
                  };
                },
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? 'lightblue' : 'white',
                  color: state.isFocused ? 'black' : 'gray',
                  cursor: 'pointer',
                }),
              }}
            />
          </div>
          
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <Select
                className="w-full"
                options={creationStatus}
                placeholder="Creation fee"
                onChange={handleCreationChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: 'gray-400',
                    primary: 'border',
                  },
                })}
                styles={{
                  placeholder: (defaultStyles) => {
                    return {
                      ...defaultStyles,
                      color: 'gray-600',
                      fontStyle: 'italic',
                      fontWeight: 'semibold',
                    };
                  },
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? 'lightblue' : 'white',
                    color: state.isFocused ? 'black' : 'gray',
                    cursor: 'pointer',
                  }),
                }}
              />
            </div>
          
        </div>

        <div className="flex w-full justify-around md:w-[44%]">
          

          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <button className="w-full h-full text-left pl-3 pr-3 py-1 text-base border-gray-300 border-1 focus:outline-none focus:ring-indigo-500 italic text-gray-800 flex justify-between items-center" 
            onClick={toggleCreationDatePicker}>
                {/* {startDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : "Select Date Range"} */}
                <span>Select Date Range</span>
                <FaCalendar className="text-slate-400 text-right right-0"/>
              </button>
              {isCreationDateickerVisible && (
                <div className="absolute z-50 bg-white border-2 border-gray-300 p-4">
                  <DatePicker
                    selected={creationStartDate}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setCreationStartDate(start);
                      setCreationEndDate(end);
                      setCreationDatePickerVisible(false);
                    }}
                    startDate={creationStartDate}
                    endDate={creationEndDate}
                    selectsRange
                    inline
                  />
                </div>
              )}
          </div>

          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <input
              type="number"
              className="w-full h-full text-left px-3 py-1 text-base border-gray-300 border-1 italic placeholder:text-gray-800"
              placeholder="Max. Product Count"
              value={productCount}
              onChange={handleMaxProductCount}
            />
          </div>
        </div>
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto"
          onClick={handleFilter}
          >
            Filter
          </button>
        </div>
      </div>

      {/**Display User / Filtered Orders */}
      <div className="w-100 container mx-auto overflow-auto pb-8">
        {Array.isArray(filteredDSMs) && filteredDSMs.length > 0 ? (
          filteredDSMs
            .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
            .map((dsm) => <DSMItem key={dsm.id} dsm={dsm} />)
        ) : (
          <p className="w-100 self-center text-center font-bold text-gray-600 m-4 mx-auto">No 
          <span className='text-custom-blue'> {activeItem} </span> 
          DSMs to Display!</p>
        )}
      </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={dsmData.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

export default DSMsTable;
