import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Pagination from 'react-js-pagination';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

// Icons
import { FaCalendar } from "react-icons/fa";

// Local Imports
import PackageItem from './PackageItem';



const PackagesTable = () => {
    //Pagination Controls
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 5;

    const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
    };
  
  
    // Paackage Data from user Object
    const [packageData, setPackageData] = useState([]);
    const [filteredPacks, setFilteredPacks] = useState([]);
    const [packNames, setPackNames] = useState([]);
    const [filtered, setFiltered] = useState(false);

    const currentUser =  JSON.parse(localStorage.getItem('user'))['user_data'];
    const accessToken = localStorage.getItem('accessToken');
    
    useEffect(() => {
      const getPackage = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/package/user/${currentUser.id}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          
          //console.log("Orders Data: " + response.data);
    
          if (response.status >= 200 && response.status < 300) {
            setPackageData(response.data);
          } else {
            console.error(`Error retrieving data: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error retrieving data: ${error}`);
        }
      };
    
      getPackage();
    }, [currentUser.id, accessToken]);
    
    
    
    
    useEffect(() => {
      // Initialize package data from localStorage only once on component mount
      // const user = JSON.parse(localStorage.getItem('user'));
      // const packages = Array.isArray(user?.package_data) ? user.package_data : [];
      // setPackageData(packages);
      setFilteredPacks(packageData);
    
      const tempPackNames = packageData.map(pack => pack.package_name);
      const formattedPackNames = tempPackNames.map(packName => ({
        value: packName,
        label: packName.toString(),
      }));
      const defaultValue = { value: 'default', label: 'All Packages' };
      formattedPackNames.unshift(defaultValue);
      setPackNames(formattedPackNames);
    }, [packageData]);


    // Click Filter Button
    const [useEffectTrigger, setUseEffectTrigger] = useState(0);
    const handleFilter = () => {
      setUseEffectTrigger(prev => prev + 1);
    };
  
    // Order DropLists filter
    const [selectedName, setSelectedName] = useState(null);

    const handleName = (selectedOption) => {
      setSelectedName(selectedOption);
    };

    // Date Range Filter
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);

    const toggleDatePicker = () => setDatePickerVisible(!isDatePickerVisible);

    // Product Count
    const [productCount, setProductCount] = useState(null);

    const handleMaxProductCountChange = (event) => {
      setProductCount(event.target.value);
    };

  
    // Multiple Order Type filter
    const status = [
      { value: 'all', label: 'All Packages' },
      { value: 'active', label: 'ACTIVE Packages' },
      { value: 'paused', label: 'Paused Packages' },
      { value: 'nonactive', label: 'NonActive Packages' },
    ];

    const [selectedStatus, setSelectedStatus] = useState(null);

    const handleStatus = (selectedOption) => {
      setSelectedStatus(selectedOption);
    };
    //console.log(selectedStatus);


    // Package Status Filter
    useEffect(() => {
      if (selectedStatus) {
        if (selectedStatus.value === 'all') {
          // Nothing to filter
          setFilteredPacks(packageData);
          setSelectedName(null);
          setSelectedStatus(null);
          setStartDate(null);
          setEndDate(null);
          setFiltered(false);
        } else {
          //console.log("Filtering by STATUS:  "  + selectedStatus.value);
          const statusesToFilterBy = selectedStatus.value;

          if (statusesToFilterBy) {
            const FilteredPacks = packageData.filter(pack => {
              
              return pack.package_status === statusesToFilterBy;
            });
            
            setFilteredPacks(FilteredPacks);
            setFiltered(true);
          } else {
           
            setFilteredPacks([]);
          }
        }
      }
    }, [selectedStatus]);
    

    // Order Number Filter
    useEffect(() => {
      if (selectedName) {
        if (selectedName.value === 'default') {
          // Nothing to filter
          setSelectedStatus('All Packages');
          setFilteredPacks(packageData);
          setSelectedName(null);
          setStartDate(null);
          setEndDate(null);
          setFiltered(false);
        } else {
          // Filter based on the selected order number
          const nameFilteredPacks = filteredPacks.filter(pack => 
            pack.package_name === selectedName.value
          );
          setFilteredPacks(nameFilteredPacks);
          setFiltered(true);
        }
      }
    }, [selectedName]);

    // Date Range Filter
    useEffect(() => {
        if (startDate === '' || endDate === '') {
          // Additional date filtering
          const nameFilteredPacks = filteredPacks.filter(pack => {
            const packDate = new Date(pack.creation_date);
    
            if (startDate && endDate) {
              return packDate >= startDate && packDate <= endDate;
            } else if (startDate) {
              return packDate >= startDate;
            } else if (endDate) {
              return packDate <= endDate;
            }
            return true; 
          });
          setFilteredPacks(nameFilteredPacks);
          setFiltered(true);
        }
      
    }, [startDate, endDate, useEffectTrigger]);


    // Order Total Range Filter
    useEffect(() => {
      if (productCount) {
        const numFilteredPacks = filteredPacks.filter(pack => {
          const numProducts = parseFloat(pack.num_of_products);

            return numProducts <= productCount;
        });
        setFilteredPacks(numFilteredPacks);
        setFiltered(true);
      }
    
    }, [productCount, useEffectTrigger]);
  

  return (
    <>

      {/* Filtration section Apply filter button */}
      <div className=" flex w-full flex-wrap items-center justify-evenly border-b-2 border-gray-300 bg-white p-3 shadow-md">
        <div className="flex w-full justify-around md:w-[46%]">
          {/** Package Name Selections */}
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <Select
            className="w-full"
            options={packNames}
            placeholder="Package Name"
            onChange={handleName}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'gray-400',
                primary: 'border',
              },
            })}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: 'gray-600',
                  fontStyle: 'italic',
                  fontWeight: 'semibold',
                };
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? 'lightblue' : 'white',
                color: state.isFocused ? 'black' : 'gray',
                cursor: 'pointer',
              }),
            }}
          />
          </div>
          {/** Creation Date Range Selection */}
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
            <button className="w-full h-full text-left pl-3 pr-3 py-1 text-base border-gray-300 border-1 focus:outline-none focus:ring-indigo-500 italic text-gray-800 flex justify-between items-center" onClick={toggleDatePicker}>
              {/* {startDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : "Select Date Range"} */}
              <span>Creation Date Range</span>
              <FaCalendar className="text-slate-400 text-right right-0"/>
            </button>
            {isDatePickerVisible && (
              <div className="absolute z-50 bg-white border-2 border-gray-300 p-4">
                <DatePicker
                  selected={startDate}
                  onChange={(dates) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                    setDatePickerVisible(false);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              </div>
            )}
          </div>
        </div>

        {/** 2nd Filtration Section */}
        <div className="flex w-full justify-around md:w-[44%]">
          {/** Package Status Filter */}
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <Select
            className="w-full"
            options={status}
            placeholder="Package Status"
            onChange={handleStatus}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'gray-400',
                primary: 'border',
              },
            })}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: 'gray-600',
                  fontStyle: 'italic',
                  fontWeight: 'semibold',
                };
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? 'lightblue' : 'white',
                color: state.isFocused ? 'black' : 'gray',
                cursor: 'pointer',
              }),
            }}
          />
          </div>
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <input
              type="number"
              className="w-full h-full text-left px-3 py-1 text-base border-gray-300 border-1 italic placeholder:text-gray-800"
              placeholder="Max. Product Count"
              value={productCount}
              onChange={handleMaxProductCountChange}
            />
          </div>
        </div>
        <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto">
            Filter
          </button>
        </div>
      </div>

      {/**Display User / Filtered Orders */}
      <div className="w-100 container mx-auto overflow-auto pb-8">
        {(filteredPacks.length === 0 && !filtered ? packageData : filteredPacks)
          .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
          .map((pack) => {
            return pack ? <PackageItem key={pack.id} pack={pack} /> : null;
          })}
      </div>
      { filteredPacks.length === 0 && filtered ?
       <p className="w-100 self-center text-center font-bold text-gray-600 m-4 mx-auto">There are no 
              <span className='text-custom-blue'> Packages </span> 
              that matches your search!</p> : null}

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={packageData.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

export default PackagesTable;
