import { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import $ from 'jquery';
import 'select2';

// boostrap styling componenets
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Button } from 'react-bootstrap';
import { BsFillPersonLinesFill,
         BsBuildingsFill,
         BsCreditCard2BackFill,
         BsCheckCircleFill,
         BsFillEyeFill
         } from "react-icons/bs";

// Local Styling imports
import './Signup.css';
import './label.css';


// Validation Schemes
import * as Yup from 'yup';

// Personal Info Form (DONE)
const personalInfoValidationSchema = Yup.object().shape({
  fullname: Yup.string().required('Full Name is required'),
  email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
      // .test('is-business-email', 'Public email addresses are not allowed', (value) => {
      //   const forbiddenDomains = ['@gmail.com', '@hotmail.com', '@outlook.com', '@yahoo.com', '@mail.ru', '@yandex.com', '@web.de', '@gmx.de', '@orange.fr', '@libero.it']; 
      //   return value && !forbiddenDomains.some(forbiddenDomain => value.endsWith(forbiddenDomain));
      // }),
  uphone: Yup.string().required('Phone Number is required'),
  function: Yup.string().required('Job Title or Function is required'),
  department_name: Yup.string().required('Department Name is required'),
  department_code: Yup.string().required('Department Code is required'),
  password: Yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required('Password is required'),
  passwordconfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirm is required'),
});

// Company Info Form (DONE)
const companyInfoValidationSchema = Yup.object().shape({
  cname: Yup.string().required('Company Name is required'),
  //type: Yup.string().required('Type is required'),
  cod_number: Yup.string().required('Chamber of Commerce number is required'),
  vat: Yup.number()
  .required('VAT number is required')
  .test('is-decimal', 'VAT number must be a decimal with no more than 2 decimal places', (value) => {
    return (value.toString().indexOf(".") < 0 || value.toString().split(".")[1].length <= 2);
  }),
  caddress: Yup.string().required('Company Address is required'),
  cpostalcode: Yup.string().required('Postal Code is required'),
  ccity: Yup.string().required('City is required'),
  ccountry: Yup.string().required('Country is required'),
  mainoffice: Yup.string().required('Main Office is required'),
});

// Payment Info Form (DONE)
const paymentInfoValidationSchema = Yup.object().shape({
  invoicing_address: Yup.string().required('Payment Address is required'),
  bank: Yup.string().required('Bank is required'),
  IBAN: Yup.string().required('IBAN is required'),
  swift_code: Yup.string().required('SWIFT code is required'),
  PO_number: Yup.string().required('PO Number is required'),
  budget_holder_name: Yup.string().required('Budget Holder is required'),
  budget_holder_email: Yup.string()
      .email('Invalid email')
      .required('Email is required')
      .test('is-business-email', 'Public email addresses are not allowed', (value) => {
        const forbiddenDomains = ['@gmail.com', '@hotmail.com', '@outlook.com', '@yahoo.com', '@mail.ru', '@yandex.com', '@web.de', '@gmx.de', '@orange.fr', '@libero.it']; 
        return value && !forbiddenDomains.some(forbiddenDomain => value.endsWith(forbiddenDomain));
      }),
  budget_holder_phone: Yup.string().required('Budget Holder Phone is required'),
});

// Delivary Info Form (DONE)
const deliveryInfoValidationSchema = Yup.object().shape({
  sdaddress: Yup.string().required('Delivery Address is required'),
  daddress: Yup.string().required('Postal Address is required'),
  dpostal: Yup.string().required('Postal Code is required'),
  dcity: Yup.string().required('City is required'),
  ddept: Yup.string().required('Department is required'),
  route_number: Yup.string().required('Route number is required'),
  contact_person_name: Yup.string().required('Contact Personal Name is required'),
  contact_person_email: Yup.string()
    .email('Invalid email')
    .required('Contact Personal Email is required')
    .test('is-business-email', 'Public email addresses are not allowed', (value) => {
      const forbiddenDomains = ['@gmail.com', '@hotmail.com', '@outlook.com', '@yahoo.com', '@mail.ru', '@yandex.com', '@web.de', '@gmx.de', '@orange.fr', '@libero.it']; 
      return value && !forbiddenDomains.some(forbiddenDomain => value.endsWith(forbiddenDomain));
    }),
  contact_person_phone: Yup.string().required('Contact Personal Phone is required'),
});



// Function to get today's date in YYYY-MM-DD format
const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Function to create and post an admin notification record
async function sendNotificationData(allValues, company_data) {
  const notificationData =  {
    itemId: company_data.id,
    title: "New Company Registraion",
    ntype: "entity",
    date: getFormattedDate(),
    description: `A new Entity has requested to be reqistered by the name of ${allValues.company_data.cname}, by User ${allValues.user_data.fullname}, email: ${allValues.user_data.email}, phone ${allValues.user_data.uphone}, Click on Preview to decide on its approval.`,
    status: "unread"
  };

  console.log('Notification Data:', notificationData);

  const clientData = {
    comapny: allValues.company_data.cname,
    name: allValues.user_data.fullname,
    email: allValues.user_data.email,
    phone: allValues.user_data.uphone
  }

  const emailData = {notification_data: notificationData, client_data: clientData};

  console.log('Notification Data:', emailData);

  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/notifications/create/`, notificationData);
    if (response.status >= 200 && response.status < 300) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      
      console.log('Sending notification email to client ...');
      // Proceed with the second post request
      const secondResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/send-client-notification/`, emailData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      });
      if (secondResponse.status >= 200 && secondResponse.status < 300) {
        console.log('Second notification created successfully:', secondResponse.data);
    } else {
      console.error('Second notification creation failed:', secondResponse.status);
      }


      const thirdResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/send_approval_email/`, clientData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      });
      if (thirdResponse.status >= 200 && thirdResponse.status < 300) {
        console.log('Third notification created successfully:', thirdResponse.data);
    } else {
      console.error('Third notification creation failed:', thirdResponse.status);
      }
      
      // Handle the case where the first request was not successful
      console.error('First notification creation failed:', response.status);
    }
    //console.log('Notification created successfully:', response.data);
  } catch (error) {
    console.error('Error creating notification:', error.response.data);
  }
}

function SignUpForm() {
  const { t } = useTranslation();
  // Handling forward Tabs actions
  const [activeKey, setActiveKey] = useState('personal');
  const [enabledTabs, setEnabledTabs] = useState(['personal']);
  const [visitedTabs, setVisitedTabs] = useState(['personal']);
  const navigate = useNavigate();

  const handleSelect = (key) => {
    if (enabledTabs.includes(key)) {
      setActiveKey(key);
      if (!visitedTabs.includes(key)) {
        setVisitedTabs([...visitedTabs, key]);
      }
    }
  };

  function enableNextTab () {
    const tabsOrder = ['personal', 'company', 'payment', 'delivary'];
    const nextTab = tabsOrder[tabsOrder.indexOf(activeKey) + 1];
    if (nextTab) {
      setEnabledTabs([...enabledTabs, nextTab]);
      setActiveKey(nextTab);
      if (!visitedTabs.includes(nextTab)) {
        setVisitedTabs([...visitedTabs, nextTab]);
      }
    }
  };

  // Handling Language selection
  const languageRef = useRef();

  useEffect(() => {
      $(languageRef.current).select2();
  }, []);

  // Handling Password visibility
  const [inputType, setInputType] = useState("password");
  const handleClickShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };


  
  // Handling REgistartion Form Submission
  const [submissionCount, setSubmissionCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filteredValues, setFilteredValues] = useState({});

  const handleSubmitAll = async () => {
    const allValues = {
      user_data: filteredValues,
      company_data: formikCompany.values,
      payment_data: formikPayment.values,
      delivery_data: formikDelivery.values
    };

      console.log("Registering...");
      console.log(allValues);
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/register/`, allValues,
        {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        }
      }
      )
        .then(response => {
          sendNotificationData(allValues, response.data);
          console.log("Sucessfully registered");
          console.log(response.data);
          navigate('/confirmr');
        })
        .catch(error => {
          if (error.response) {
            setErrorMessage('Registration Process has Failed, Please Try to register again!  ' + error.response.status, error.response.message);
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            setErrorMessage('Registration Process has Failed, Please Try to register again!  ' + error.response.status, error.response.message);
            console.log(error.response.data);
          } else {
            // Something happened in setting up the request that triggered an Error
            setErrorMessage('Registration Process has Failed, Please Try to register again!  ' + error.response.status, error.response.message);
            console.log(error.response.data);
          }
        });
  };


  // Function to format date as YYYY-MM-DD
  const formatDate = (date) => {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
  
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
  
      return [year, month, day].join('-');
    };

  // Get today's date
  const today = new Date();
  const birthDate = formatDate(today);


  // Handle fetching User data (DONE)
  const formikPersonal = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      uphone: '',
      function: '',
      department_name: '',
      department_code: '----',
      date_of_birth: birthDate,
      language_preference: '',
      password: '',
      budget: 10000,
    },
    validationSchema: personalInfoValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      // Handle form submission here
      const { passwordconfirm, ...filteredValues } = values;
      console.log("In Validation");
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/check-email/`, { email: values.email });
        
        if (response.data.error) {
          setErrorMessage(response.data.error);
          console.log("Response Data Error: ");
        } else {
          console.log("Response Successful");
          setFilteredValues(filteredValues);
          enableNextTab();
          setSubmissionCount(prevCount => prevCount + 1);
          if (submissionCount === 3) {
            handleSubmitAll();
          }
        }
      } catch (error) {
        setErrorMessage("There's an account with this email address in the database, Try logging in or contact your company's Head user for more information.");
        console.log("There's an account with this email address in the database, Try logging in or contact your company's Head user for more information.");
      } finally {
        setSubmitting(false);
      }
    
    },
  });


  const personalRequiredFields = {
    fullname: 'Full Name',
    email: 'Email',
    uphone: 'Phone Number',
    function: 'Function',
    department_name: 'Department Name',
    language_preference: 'Language Preference'
  };

  const personalMissingFields = Object.keys(personalRequiredFields).filter(field => !formikPersonal.values[field]);
  const personalMissingFieldsDisplay = personalMissingFields.map(field => personalRequiredFields[field]);
  
  // Get today's date
  const contractStartDate = formatDate(today);

  // Get the date one year from today
  const nextYear = new Date(today);
  nextYear.setFullYear(today.getFullYear() + 1);
  const contractEndDate = formatDate(nextYear);
  
  // Handling Fetching Company Data (DONE)
  const formikCompany = useFormik({
    initialValues: {
      cname: '',
      //type: '',
      cod_number: '',
      vat: '',
      caddress: '',
      cpostalcode: '',
      ccity: '',
      ccountry: '',
      mainoffice: '',
      sla_1: 'No Service Level Agreements Registered',
      sla_2: 'No Service Level Agreements Registered',
      sla_3: 'No Service Level Agreements Registered',
      sla_4: 'No Service Level Agreements Registered',
      sla_5: 'No Service Level Agreements Registered',
      contract_start_date: contractStartDate,
      contract_end_date: contractEndDate,
    },
    validationSchema: companyInfoValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      // console.log(values);
    
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/check-company-name/`, { companyName: values.cname });
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          enableNextTab();
          setSubmissionCount(prevCount => prevCount + 1);
          if (submissionCount === 3) {
            handleSubmitAll();
          }
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('An error occurred while checking the Organization name. Please try again.');
        }
      } finally {
        setSubmitting(false);
      }


    },
  });

  
  // Handling Fetching Payment Data (DONE)
  const formikPayment = useFormik({
    initialValues: {
      pay_name: 'Main Payment Profile',
      invoicing_address: '',
      bank: '',
      IBAN: '',
      swift_code: '',
      PO_number: '',
      budget_holder_name: '',
      budget_holder_email: '',
      budget_holder_phone: '',
    },
    validationSchema: paymentInfoValidationSchema,
    onSubmit: (values, {setSubmitting}) => {
      //console.log(values);
      // Handle form submission here
      enableNextTab();
      setSubmitting(false);
      setSubmissionCount(prevCount => prevCount + 1);
      if (submissionCount === 3) {
        handleSubmitAll();
      }
    },
  });


  // Handling Fetching Delivery Data (DONE)
  const formikDelivery = useFormik({
  initialValues: {
    del_name: 'Main Delivery Profile',
    sdaddress: '----',
    daddress: '',
    dpostal: '',
    dcity: '',
    ddept: '',
    route_number: '',
    contact_person_name: '',
    contact_person_email: '',
    contact_person_phone: '',
  },
  validationSchema: deliveryInfoValidationSchema,
  onSubmit: (values, {setSubmitting}) => {
    // console.log(values);
    // Handle form submission here
    setSubmitting(false);
    setSubmissionCount(prevCount => prevCount + 1);
    if (submissionCount === 3) {
      handleSubmitAll();
    }
  },
});

  return (
    <Tabs
      activeKey={activeKey}
      onSelect={handleSelect}
      className="mb-3"
      justify
    >
      
      {/** User Data Form */}
      <Tab eventKey="personal" title={ 
        <div className={`tab-title-container 
                  ${visitedTabs.includes('personal') ? 'visited' : ''} 
                  ${activeKey === 'personal' ? 'active' : ''}`}>
          <BsFillPersonLinesFill className="tab-icon"/>
          <span className="tab-small-text">{t('signprocess.step1')}</span>
          <span className="tab-text">{t('signprocess.signint1')}</span>
        </div>
      }>
      <div className="p-3 custom-width">
      <form onSubmit={formikPersonal.handleSubmit}>
        <Form.Group className="mb-3">
          <FormControl 
            type="name" 
            className="inputArea"
            id="fullname" 
            placeholder="Full Name"
            onChange={formikPersonal.handleChange}
            onBlur={formikPersonal.handleBlur} 
            value={formikPersonal.values.fullname}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <FormControl 
            type="email" 
            className="inputArea"
            id="email" 
            placeholder="Business Email"
            onChange={formikPersonal.handleChange}
            onBlur={formikPersonal.handleBlur} 
            value={formikPersonal.values.email}
            required
          />
          {formikPersonal.errors.email && <div style={{color:'red'}}>{formikPersonal.errors.email}</div>}
        </Form.Group>

        <Form.Group className="mb-3">
          <FormControl 
            type="phone" 
            className="inputArea"
            id="uphone" 
            placeholder="Phone Number"
            onChange={formikPersonal.handleChange}
            onBlur={formikPersonal.handleBlur} 
            value={formikPersonal.values.uphone}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <FormControl 
            type="text" 
            className="inputArea"
            id="function" 
            placeholder="Job Title or Function"
            onChange={formikPersonal.handleChange}
            onBlur={formikPersonal.handleBlur} 
            value={formikPersonal.values.function}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <FormControl 
            type="text" 
            className="inputArea"
            id="department_name" 
            placeholder="Department Name"
            onChange={formikPersonal.handleChange}
            onBlur={formikPersonal.handleBlur} 
            value={formikPersonal.values['dept-name']}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <div className="hover-container w-full">
            <FormControl 
              type="date" 
              className="inputArea"
              id="birthdate" 
              placeholder="Date of Birth (not required)"
              onChange={formikPersonal.handleChange}
              onBlur={formikPersonal.handleBlur}
              value={formikPersonal.values.birthdate}
            />
            <label className="hover-label" htmlFor="birthdate">Date of Birth (not required)</label>
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <FormControl 
            as="select" 
            className="inputArea custom-select"
            id="language_preference" 
            onChange={formikPersonal.handleChange}
            onBlur={formikPersonal.handleBlur} 
            value={formikPersonal.values.language}
            required
          >
            <option value="">Select Preferred Language</option>
            <option value="en">English</option>
            <option value="du">Dutch</option>
            <option value="de">German</option>
          </FormControl>
        </Form.Group>
      
        <Form.Group className="mb-3">
          <InputGroup>
            <FormControl 
              type={inputType} 
              className="inputArea"
              id="password" 
              placeholder="Password"
              onChange={formikPersonal.handleChange}
              onBlur={formikPersonal.handleBlur} 
              value={formikPersonal.values.password}
              required
            />

            <Button 
              variant="outline-secondary" 
              onClick={handleClickShowPassword} 
              className="inputArea button-no-border"
              type="button"
            >
              <BsFillEyeFill />
            </Button>
          </InputGroup>
          {formikPersonal.errors.password && <div style={{color: 'red'}}>{formikPersonal.errors.password}</div>}
        </Form.Group>

        <Form.Group className="mb-3">
          <InputGroup>
            <FormControl 
              type={inputType}
              className="inputArea"
              id="passwordconfirm" 
              placeholder="Confirm Password"
              onChange={formikPersonal.handleChange}
              onBlur={formikPersonal.handleBlur} 
              value={formikPersonal.values.passwordconfirm}
              required
            />

            <Button 
              variant="outline-secondary" 
              onClick={handleClickShowPassword} 
              className="inputArea button-no-border"
              type="button"
            >
              <BsFillEyeFill />
            </Button>
          </InputGroup>
          {formikPersonal.errors.passwordconfirm && <div style={{color:'red'}}>{formikPersonal.errors.passwordconfirm}</div>}
        </Form.Group>

        {personalMissingFieldsDisplay.length > 0 && (
        <div className="text-sm text-orange-400 font-semibold text-center w-full" role="alert">
          The following fields are required: {personalMissingFieldsDisplay.join(', ')}
        </div>
      )}
        
        
        {errorMessage && (
        <div className="text-sm text-red-500 font-semibold text-center w-full" role="alert">
          {errorMessage}
        </div>
      )}
        <button type="submit" 
                className="btn btn-primary mb-4 w-100 button3D"
                disabled={!formikPersonal.isValid || !formikPersonal.dirty || errorMessage !== null}>
            {t('signprocess.continuebtn')}
        </button>
      </form>
        <div className="bottom-note">
                <span className="text-left p-tag">
                {t('signprocess.haveaaccount')}
                </span>
                
                <Button className="btnLogin">
                <Link to="/login">{t('signprocess.loginbtn')}</Link></Button>
                
            </div>
        </div>
      </Tab>

      {/** Company Info Tab  */}
      <Tab eventKey="company" title={ 
          <div className={`tab-title-container 
              ${visitedTabs.includes('company') ? 'visited' : ''} 
              ${activeKey === 'company' ? 'active' : ''}`}>
             <BsBuildingsFill className="tab-icon"/>
             <span className="tab-small-text">{t('signprocess.step2')}</span>
             <span className="tab-text">{t('signprocess.signint2')}</span>
           </div>
      }>
        <div className="p-3 custom-width">
        <Form onSubmit={formikCompany.handleSubmit}>
            <Form.Group className="mb-3">
              <FormControl
                type="name"
                className="inputArea"
                id="cname"
                placeholder="Organization Name"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.cname}
                required
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <FormControl
                type="text"
                className="inputArea"
                id="type"
                placeholder="Business Type"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.type}
                required
              />
            </Form.Group> */}
            <Form.Group className="mb-3">
              <FormControl
                type="text"
                className="inputArea"
                id="cod_number"
                placeholder="Chamber of Commerce Number"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.cod_number}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl
                type="text"
                className="inputArea"
                id="vat"
                placeholder="VAT Number"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.vat}
                required
              />
              {formikCompany.errors.vat && <div style={{color:'red'}}>{formikCompany.errors.vat}</div>}
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl
                type="address"
                className="inputArea"
                id="caddress"
                placeholder="Business Address"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.caddress}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl
                type="postal"
                className="inputArea"
                id="cpostalcode"
                placeholder="Postal Code"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values['cpostal-code']}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl
                type="text"
                className="inputArea"
                id="ccity"
                placeholder="City"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.ccity}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl
                type="text"
                className="inputArea"
                id="ccountry"
                placeholder="Country"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values.ccountry}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl
                type="address"
                className="inputArea"
                id="mainoffice"
                placeholder="Location of Main Office"
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                value={formikCompany.values['main-office']}
                required
              />
            </Form.Group>

            {errorMessage && (
              <div className="text-sm text-red-500 font-semibold text-center w-full" role="alert">
                {errorMessage}
              </div>
            )}

            <button type="submit" 
                className="btn btn-primary mb-4 w-100 button3D"
                disabled={!formikCompany.isValid || !formikCompany.dirty || errorMessage !== null}>
                {t('signprocess.continuebtn')}
            </button>
          </Form>
        </div>
      </Tab>

      {/** Payment Profile Info */}
      <Tab eventKey="payment" title={ 
            <div className={`tab-title-container 
              ${visitedTabs.includes('payment') ? 'visited' : ''} 
              ${activeKey === 'payment' ? 'active' : ''}`}>
              <BsCreditCard2BackFill className="tab-icon"/>
              <span className="tab-small-text">{t('signprocess.step3')}</span>
              <span className="tab-text">{t('signprocess.signint3')}</span>
            </div>
      }>
          <div className="p-3 custom-width">
            <Form onSubmit={formikPayment.handleSubmit}>
              <Form.Group className="mb-3">
                  <FormControl 
                    type="address" 
                    className="inputArea" 
                    id="invoicing_address" 
                    placeholder="Billing Address" 
                    onChange={formikPayment.handleChange} 
                    required 
                  />
              </Form.Group>

              <Form.Group className="mb-3">
                <div className="hover-container w-full">
                  <FormControl 
                    type="text" 
                    className="inputArea" 
                    id="PO_number" 
                    placeholder="PO Number" 
                    onChange={formikPayment.handleChange} 
                    required 
                  />
                  <label className="hover-label" htmlFor="birthdate">Purchase Order Number</label>
                  </div>
              </Form.Group>

              <Form.Group className="mb-3">
                  <FormControl 
                    type="text" 
                    className="inputArea" 
                    id="bank" 
                    placeholder="Bank Name" 
                    onChange={formikPayment.handleChange} 
                    required 
                  />
              </Form.Group>
              <Form.Group className="mb-3">
                  <FormControl 
                      type="text" 
                      className="inputArea" 
                      id="IBAN" 
                      placeholder="IBAN Number" 
                      onChange={formikPayment.handleChange} 
                      required 
                    />
              </Form.Group>
              <Form.Group className="mb-3">
                  <FormControl 
                      type="text" 
                      className="inputArea" 
                      id="swift_code" 
                      placeholder="BIC/SWIFT Number" 
                      onChange={formikPayment.handleChange} 
                      required 
                    />
              </Form.Group>
              <Form.Group className="mb-3">
                  <FormControl 
                      type="name" 
                      className="inputArea" 
                      id="budget_holder_name" 
                      placeholder="Budget Holder Name" 
                      onChange={formikPayment.handleChange} 
                    />
              </Form.Group>
              <Form.Group className="mb-3">
                  <FormControl 
                      type="email" 
                      className="inputArea" 
                      id="budget_holder_email" 
                      placeholder="Budget Holder Email" 
                      onChange={formikPayment.handleChange} 
                      required 
                    />
                    {formikPayment.errors.budget_holder_email && <div style={{color:'red'}}>{formikPayment.errors.budget_holder_email}</div>}
              </Form.Group>
              <Form.Group className="mb-3">
                  <FormControl 
                      type="phone" 
                      className="inputArea" 
                      id="budget_holder_phone" 
                      placeholder="Budget Holder Phone" 
                      onChange={formikPayment.handleChange} 
                      required 
                    />
              </Form.Group>
        
              {/* <PaymentForm handleInputChange={handleInputChange} /> */}
              <button type="submit" 
                    className="btn btn-primary mb-4 w-100 button3D"
                    disabled={!formikPayment.isValid || !formikPayment.dirty}>
                {t('signprocess.continuebtn')}</button>
          </Form>
        </div>
      </Tab>

      {/** Delivary Profile Info */}
      <Tab eventKey="delivary" title={ 
          <div className={`tab-title-container 
            ${visitedTabs.includes('delivary') ? 'visited' : ''} 
            ${activeKey === 'delivary' ? 'active' : ''}`}>
            <BsCheckCircleFill className="tab-icon"/>
              <span className="tab-small-text">{t('signprocess.step4')}</span>
              <span className="tab-text">{t('signprocess.signint4')}</span>
          </div>
      }>
          <div className="p-3 custom-width">
          <Form onSubmit={formikDelivery.handleSubmit}>
            {/* <Form.Group className="mb-3">
              <FormControl 
                type="address" 
                className="inputArea" 
                id="sdaddress" 
                placeholder="Standard Delivery Address" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.sdaddress}
                required 
              />
            </Form.Group> */}
            <Form.Group className="mb-3">
              <FormControl 
                type="address" 
                className="inputArea" 
                id="daddress" 
                placeholder="Profile Delivery Address" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.invoicing_address}
                required 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="postal" 
                className="inputArea" 
                id="dpostal" 
                placeholder="Postal Code" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.dpostal}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="city" 
                className="inputArea" 
                id="dcity" 
                placeholder="City" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.dcity}
                required 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="text" 
                className="inputArea" 
                id="ddept" 
                placeholder="Department" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.ddept}
                required 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="text" 
                className="inputArea" 
                id="route_number" 
                placeholder="Route Number" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.route_number}
                required 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="name" 
                className="inputArea" 
                id="contact_person_name" 
                placeholder="Contact Personal Name" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.dname}
                required 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="email" 
                className="inputArea" 
                id="contact_person_email" 
                placeholder="Contact Personal Email" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.contact_person_email}
                required 
              />
              {formikDelivery.errors.contact_person_email && <div style={{color:'red'}}>{formikDelivery.errors.contact_person_email}</div>}
            </Form.Group>
            <Form.Group className="mb-3">
              <FormControl 
                type="phone" 
                className="inputArea" 
                id="contact_person_phone" 
                placeholder="Contact Personal Phone" 
                onChange={formikDelivery.handleChange} 
                onBlur={formikDelivery.handleBlur}
                value={formikDelivery.values.contact_person_phone}
                required 
              />
            </Form.Group>

            <button type="submit" 
              className="btn btn-primary mb-4 w-100 button3D"
              disabled={!formikDelivery.isValid || !formikDelivery.dirty}>
              {t('signprocess.registerbtn')}
            </button>
          </Form>
          {/**Untried */}
          {errorMessage && (
              <div className="text-sm text-red-500 font-semibold text-center w-full" role="alert">
                {errorMessage}
              </div>
            )}
        </div>
      </Tab>
    </Tabs>
  );
}

export default SignUpForm;