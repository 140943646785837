import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

// Local Imports
import DSMProductItem from './DSMProductItem';

const DSMItem = ({ dsm }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // const { name, creationDate, productCount, lastreview, status, products } =
  //   dsm;
  // console.log(dsm.products.length);
  // console.log(dsm.products);

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-2 shadow">
        <div className="w-100 flex w-full flex-col justify-center p-1 sm:flex-row sm:items-center sm:justify-center">
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">DSM Name</p>
            <span className="text-sm text-gray-500">{dsm.dsm_name}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Creation Date</p>
            <span className="text-sm text-gray-500">{dsm.creation_date.slice(0,10)}</span>
          </div>
          <div className="mr-2 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Product Count</p>
            <span className="text-sm text-gray-500">{dsm.products.length}</span>
          </div>
        </div>

        <div className="w-100 flex w-full flex-col justify-center p-1 sm:flex-row sm:items-center sm:justify-center">
          <div className="flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Status Update Date</p>
            <span className="text-sm text-gray-500">{
                dsm.dsm_status === 'active'? dsm.dsm_active_date.slice(0, 10)
                : (
                  dsm.dsm_status === 'paused'? dsm.dsm_paused_date.slice(0, 10)
                  : dsm.dsm_non_active_date.slice(0, 10)
                )}</span>
          </div>
          {/** Status Btn Section */}
          <div className="mr-12 flex w-full flex-row space-x-4 p-1 text-sm font-bold">
            {dsm.dsm_status === 'active' && (
              <button className="w-75 rounded-full border-2 bg-green-400 p-2 text-white hover:bg-green-500">
                Active
              </button>
            )}
            {dsm.dsm_status === 'non_active' && (
              <button className="w-75 rounded-full border-2 bg-red-400 p-2 text-white hover:bg-red-500">
                Non Active
              </button>
            )}
            {dsm.dsm_status === 'paused' && (
              <button className="w-75 rounded-full border-2 bg-orange-300 p-2 text-white hover:bg-orange-400">
                Paused
              </button>
            )}
          </div>
        </div>

        <button
          className="absolute inset-y-0 right-0 mr-4 focus:outline-none"
          onClick={toggleExpanded}
        >
          <IoIosArrowDown />
        </button>
      </div>

      {/**Display Products upon Expanding */}
      {isExpanded && (
        <div className="w-100 container mx-auto overflow-auto pb-8">
            {Array.isArray(dsm.products) && 
            dsm.products.length > 0 &&
            dsm.products.map((product) =>
              product ? (
                <DSMProductItem 
                  key={product.id} 
                  product={product} 
                />
              ) : null
            )
          }
        </div>
      )}
    </>
  );
};

export default DSMItem;
