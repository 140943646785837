import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

// Icon imports
import { MdApartment } from 'react-icons/md';
import { MdOutlineDateRange } from 'react-icons/md';
import { GrStatusGood } from 'react-icons/gr';
import { AiOutlineRedo } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { TbBasketPause } from 'react-icons/tb';
import { MdOutlineMotionPhotosOff } from 'react-icons/md';
import { IoMdPricetags } from 'react-icons/io';
import { TbTruckDelivery } from 'react-icons/tb';

// Select Component Style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    height: '2rem',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};

// Edit Componemt Start
export default function EditDsm({ dsmList }) {
  // Form Visibility State
  const [showEditForm, setShowEditForm] = useState(false);
  //const [DSMStatus, setDSMStatus] = useState('active');
  const [productNames, setProductNames] = useState([]);

  
  // Retrieve User Related Data
  // const user = JSON.parse(localStorage.getItem('user'));
  // const user_dsm = Array.isArray(user.dsm_data) ? user.dsm_data : [];
  const user_dsm = dsmList ? dsmList : [];

  // Set Products Info
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  // Selected DSM from DropList Module "Select"
  const [selectedDsm, setSelectedDsm] = useState(null);
  const [selectedDsmOption, setSelectedDsmOption] = useState(null);
  
  // Updating Package Status & Refill Period
  const [dsmStatus, setDsmStatus] = useState(null);
  const [statusLabel, setStatusLabel] = useState('');
  const [refillPeriod, setRefilPeriod] = useState(null);

  
  // Prepare the data to be shown in the Fourms after a new DSN is selected.
  const handleProductSelect = (selectedDsmOption) => {
    setSelectedDsmOption(selectedDsmOption);
    const dsmData = user_dsm.find(
      (dsm) => dsm.dsm_name === selectedDsmOption.value,
    );
    setSelectedDsm(dsmData);
    setStatusLabel(dsmData.dsm_status.charAt(0).toUpperCase() + dsmData.dsm_status.slice(1));

    const products =
      dsmData && dsmData.products
        ? dsmData.products.flatMap((dsmProduct) => ({
          product: storedProducts.find(product => dsmProduct.product === product.id),
              value: dsmProduct.id,
              label: dsmProduct.id,
              price: 0, // needs fixing
              lower_limit: dsmProduct.lower_spec_stock,
              upper_limit: dsmProduct.upper_spec_stock,
              status: dsmProduct.product_status,
            }),
          )
        : [];
    setProductNames(products);
    setRefilPeriod(dsmData.refil_period);
    setDsmStatus(dsmData.dsm_status);
    setLowerStockLimit({});
    setHigherStockLimit({});
    setSelectedStatus({});
    setFailureMessage(null);
    setSuccessMessage(null);
  };


  // Handling Editing Fourms appearnce and status
  const [successMessage, setSuccessMessage] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const handleEditForm = () => {
    if (selectedDsm) {
      setShowEditForm(!showEditForm);
    }
    setSuccessMessage(null);
    setFailureMessage(null);
  };

  const accessToken = localStorage.getItem('accessToken');

  
  // Handling Save Package Editing
  const handleSaveForm = async () => {
    if (dsmStatus) {
      try {
        //// console.log("DSM Status : " + dsmStatus);
        const postUpdatedDSM = {
            dsm_status: dsmStatus,
            refill_period: refillPeriod? refillPeriod : selectedDsm.refill_period,
            products: productNames.map((dsmProduct) => ({
              product: dsmProduct.product.id,
              product_status: dsmProduct.status,
              upper_spec_stock: dsmProduct.upper_limit,
              lower_spec_stock: dsmProduct.lower_limit,
          })),
        };
        //// console.log(postUpdatedDSM);
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/products/dsm/update/${selectedDsm.id}/`, 
          postUpdatedDSM,
          {headers: {
            'Authorization': `Bearer ${accessToken}`
          }}
        );

            //// console.log('File uploaded successfully:');
            //// console.log(response.data);
            // console.log(postUpdatedDSM);
            if (response.status <= 200 && response.status < 300) {
                // console.log(response);
                setSuccessMessage('DSM updated successfully!');
                setShowEditForm(false);
                setSelectedDsmOption(null);
                setSelectedDsm(null);
                setSelectedProductOption(null);
                setShowProductData(false);
                setProductNames([]);
            } else {
              
              // console.log("Edit DSM UNSuccessful!!! ", response);
              
            }
        } catch (error) {
              //// console.error('Error uploading file:', error);
              //setErrorMessage('Error uploading file');
              setFailureMessage('Error updating package!');
        }
      } else {
        //// console.log('Error uploading file:');
        setFailureMessage('Please select new status to update!');
      }
  };

  // Handling Show Package Info
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [showProductData, setShowProductData] = useState(false);
  const HandleShowData = (selectedProductOption) => {
      setSelectedProductOption(selectedProductOption);
      setShowProductData(true);
  };

  const handleCancel = () => {
    setShowEditForm(false);
    setFailureMessage(null);
    setSuccessMessage(null);
  };


  const [useEffectTrigger, setUseEffectTrigger] = useState(0);
  // Step 2: Update state on click
  const handleFilter = () => {
      setUseEffectTrigger(prev => prev + 1);
    };


  const options = user_dsm.map((dsm) => ({
    value: dsm.dsm_name,
    label: dsm.dsm_name,
  }));

  const status = [
    { value: 'active', label: 'Active' },
    { value: 'non_active', label: 'Non Active' },
    { value: 'paused', label: 'Paused' },
  ];

  const rows = [
    {
      icon: <MdApartment />,
      label: 'Department',
      info: selectedDsm ? selectedDsm.department : 'NA',
      display: 'show',
    },
    {
      icon: <MdOutlineDateRange />,
      label: 'Creation Date',
      info: selectedDsm ? selectedDsm.creation_date.substring(0, 10) : 'NA',
      display: 'show',
    },
    {
      icon: <LiaFileInvoiceDollarSolid />,
      label: 'Creation Fee',
      info: selectedDsm ? `€ ${selectedDsm.creation_fee}` : 'NA',
      display: 'show',
    },
    {
      icon: <TbTruckDelivery />,
      label: 'Number of Products',
      info:
        selectedDsm && selectedDsm.num_of_products
          ? selectedDsm.num_of_products
          : 'NA',
      display: 'show',
    },
    {
      icon: <LiaFileInvoiceDollarSolid />,
      label: 'DSM Monthly Fee',
      info:
      selectedDsm && selectedDsm.dsm_status === 'active'
        ? (selectedDsm.monthly_fee_dsm? '€' + selectedDsm.monthly_fee_dsm: 'NA')
        : 'NA',
    display:
      selectedDsm && selectedDsm.dsm_status === 'active' ? 'show' : 'hide',
    },
    {
      icon: <TbBasketPause />,
      label: 'DSM Paused Date',
      info:
        selectedDsm && selectedDsm.dsm_status === 'paused'
          ? selectedDsm.dsm_paused_date.substring(0, 10)
          : 'NA',
      display:
        selectedDsm && selectedDsm.dsm_status === 'paused' ? 'show' : 'hide',
    },
    {
      icon: <MdOutlineMotionPhotosOff />,
      label: 'DSM Non Active Date',
      info:
        selectedDsm && selectedDsm.dsm_status === 'non_active'
          ? selectedDsm.dsm_non_active_date.substring(0, 10)
          : 'NA',
      display:
        selectedDsm && selectedDsm.dsm_status === 'non_active'
          ? 'show'
          : 'hide',
    },
    {
      icon: <GrStatusGood />,
      label: 'Refill Proposal Status',
      info:
        selectedDsm && selectedDsm.dsm_status === 'active'
          ? (selectedDsm.refill_proposal_status? selectedDsm.refill_proposal_status : 'NA') 
          : 'NA',
      display:
        selectedDsm && selectedDsm.dsm_status === 'active' ? 'show' : 'hide',
    },
    {
      icon: <MdOutlineDateRange />,
      label: 'Refill Proposal Date',
      info:
        selectedDsm && selectedDsm.dsm_status === 'active'
          ? selectedDsm.refill_proposal_date.substring(0, 10)
          : 'NA',
      display:
        selectedDsm && selectedDsm.dsm_status === 'active' ? 'show' : 'hide',
    },
  ];


  // Updating product Info
  const [lowerStockLimit, setLowerStockLimit] = useState({});
  const [higherStockLimit, setHigherStockLimit] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});

  useEffect(() => {
    // Update the products list based on the state changes
    const updatedProducts = productNames.map((dsmProduct) => {
      return {
        ...dsmProduct,
        lower_limit: lowerStockLimit[dsmProduct.product.assortimed_pid] || dsmProduct.lower_limit,
        upper_limit: higherStockLimit[dsmProduct.product.assortimed_pid] || dsmProduct.upper_limit,
        status: selectedStatus[dsmProduct.product.assortimed_pid] || dsmProduct.status,
      };
    });
    setProductNames(updatedProducts);
  }, [lowerStockLimit, higherStockLimit, useEffectTrigger]);
  // selectedStatus, productNames, lowerStockLimit, higherStockLimit

  const handleInputChange = (event, productId, type) => {
    const value = event.target.value;
    if (type === 'lower') {
      setLowerStockLimit((prev) => ({ ...prev, [productId]: value }));
    } else if (type === 'higher') {
      setHigherStockLimit((prev) => ({ ...prev, [productId]: value }));
    }
  };

  const handleSelectChange = (event, productId) => {
    const value = event.target.value;
    setSelectedStatus((prev) => ({ ...prev, [productId]: value }));
  };

  return (
    <>
        {!showEditForm && (
        <div className="m-auto w-[90%] text-center">
          <h2 className="mx-auto mt-11 font-sans text-2xl font-semibold text-custom-blue">
            Choose a DSM to Edit
          </h2>
          <p className="mx-auto w-10/12 p-4 text-sm font-bold text-red-500 ">
            You can only Edit a DSM's Refill Perid, Stataus and Products Upper
            and Lower Limit, and Staus Want to Edit More ...{' '}
            <span className="text-custom-blue">Make a Copy</span> of an Existing
            DSM and customize it into a new one.
          </p>

          <div className="mx-auto mb-12 flex w-[90%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
            <Select
              options={options}
              styles={customStyles}
              className="m-auto w-full"
              placeholder="Choose ..."
              onChange={handleProductSelect}
              value={selectedDsmOption}
            />
            {showEditForm ? null : (
              <button
                onClick={handleEditForm}
                disabled={!selectedDsm}
                className={`${selectedDsm ? 'text-md rounded-lg bg-custom-blue px-28 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue' : 'text-md cursor-not-allowed rounded-lg bg-gray-300 px-28 py-1 font-semibold text-gray-500'} `}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}

      {/** DSM Head and Title */}
      {showEditForm && (
        <div className="m-auto mt-4 flex w-[90%] items-center justify-center rounded-3xl md:mb-0 md:w-[48%]">
          <h2 className="mx-auto my-3 text-center font-sans text-2xl font-bold text-custom-blue">
            {selectedDsm ? selectedDsm.dsm_name : ''}
          </h2>
        </div>
      )}

      {showEditForm && (
        <>
        { failureMessage ? 
          <p className="mx-auto text-center w-10/12 text-sm font-bold text-red-400 ">
              Some Thing went wrong, Please Try again!
          </p>
          : null}
          {/** DSM Information */}
          <div className="mx-auto flex w-[90%] flex-col justify-between py-6  md:flex-row ">
            <div className="mb-4 w-full rounded-3xl p-4 shadow-lg md:mb-0 md:w-[48%]">
              {/* <h2 className="mx-auto my-3 text-center text-lg font-sans text-custom-blue font-bold">
                {selectedDsm ? selectedDsm.dsm_name : ''}
              </h2> */}
              <h2 className="mx-auto my-3 text-center font-sans text-lg font-semibold">
                DSM Info.
              </h2>
              {/** Editable Section of the DSM */}
              <div className="mb-2 flex flex-col gap-1 sm:flex-row sm:items-center">
                <span className="text-lg text-custom-green">
                  <AiOutlineRedo />
                </span>
                <label className="label">Refill Period</label>
                <div className="left-0 flex w-[75%] flex-grow items-center justify-evenly">
                  <input
                    className="w-[50%] rounded-lg border border-stone-200 p-2 text-xs transition-all duration-300 placeholder:text-gray-800 focus:outline-none"
                    name="refill_period"
                    type="number"
                    placeholder={refillPeriod ? refillPeriod: (selectedDsm ? selectedDsm.refill_period : 'NA')}
                    onChange={(event) => {
                      setRefilPeriod(event.target.value);
                    }}
                  />
                  <span className="flex text-center text-xs font-semibold text-gray-800">
                    In Days
                  </span>
                </div>
              </div>

              <div className="mb-6 flex flex-col gap-1 sm:flex-row sm:items-center">
                <span className="text-lg text-custom-green">
                  <GrStatusGood />
                </span>
                <label className="label">Dsm Status</label>
                <div className="flex w-[75%] flex-grow">
                  <p className="h-10 w-[50%] cursor-not-allowed rounded-lg border border-stone-200 p-2 text-xs font-semibold text-gray-800 transition-all  duration-300 focus:outline-none">
                    {statusLabel}
                  </p>
                  <div className="ml-2 w-[50%]">
                    <Select
                      options={status}
                      className="w-full text-xs"
                      onChange={(selectedOption) => {
                        setDsmStatus(selectedOption.value);
                        setStatusLabel(selectedOption.label);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <h2 className="mx-auto py-2 text-center font-sans text-lg font-semibold">
                General Information
              </h2>

              <table className="w-full table-auto">
                <tbody>
                  {rows.map(
                    (row, index) =>
                      row.display === 'show' && (
                        <tr key={index}>
                          <th
                            className="w-1/4 bg-teal-50 py-2 text-left"
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <div className="flex items-center">
                              <span className="text-base font-bold text-custom-green">
                                {row.icon}
                              </span>
                              <span className="ml-3 text-sm font-semibold">
                                {row.label}
                              </span>
                            </div>
                          </th>
                          <td className="w-4/12 border-b py-2 text-center">
                            <span className="ml-2 text-sm font-semibold text-stone-600">
                              {row.info}
                            </span>
                          </td>
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </div>

            {/** Products Section */}
            <div className="w-full overflow-y-auto rounded-3xl p-4 shadow-lg md:w-[48%]">
              <h2 className="mx-auto my-3 text-center text-xl font-semibold">
                Products List
              </h2>
              <ul>
                {productNames.length > 0
                  ? productNames.map((dsmProduct, index) => (
                      <li key={index} className="mx-auto">
                        <div className="hover my-2 w-full flex-col items-center justify-center overflow-hidden rounded border bg-white p-1 shadow">
                          <div className="my-2 flex h-full w-full flex-row items-center justify-between">
                            <div className="mr-2 flex w-2/5">
                              <img
                                src={imgPaths[dsmProduct.product.assortimed_pid][0]}
                                alt="Product"
                                className="w-full flex-grow object-cover"
                              />
                            </div>
                            <div className="mr-2 flex w-full flex-col items-center">
                              <p className="mb-2 text-center text-sm font-bold text-custom-blue">
                                {dsmProduct.product.name}
                              </p>
                              <div className="flex flex-row items-center justify-between text-xs font-semibold">
                                <span>Stock Limit: </span>
                                <span className="text-amber-600">from</span>
                                <input
                                  className="w-[15%] rounded-lg border border-stone-200 p-1 text-xs transition-all duration-300 placeholder:text-stone-600  focus:outline-none"
                                  name="lower_stock_limit"
                                  placeholder={dsmProduct.lower_limit}
                                  onChange={(event) => handleInputChange(event, dsmProduct.product.assortimed_pid, 'lower')}
                                />
                                <span className="text-amber-600">to</span>
                                <input
                                  className="w-[15%] rounded-lg border border-stone-200 p-1 text-xs transition-all duration-300 placeholder:text-stone-600  focus:outline-none"
                                  name="higher_stock_limit"
                                  placeholder={dsmProduct.upper_limit}
                                  onChange={(event) => handleInputChange(event, dsmProduct.product.assortimed_pid, 'higher')}
                                />
                                <h4 className="mr-2 text-sm font-bold text-custom-blue">
                                {dsmProduct.product ? "€ " + dsmProduct.product.sales_price_c1 : 'NA'}
                                </h4>
                              </div>
                              {/**Line 2: Product Status */}
                              <div className="mb-2 mt-2 flex flex-row items-center justify-between text-xs font-semibold">
                                <span>Product Status: </span>
                                <p className=" cursor-not-allowed rounded-lg border border-stone-200 p-2 text-xs font-semibold text-gray-800 transition-all  duration-300 focus:outline-none">
                                  {dsmProduct.status
                                    ? dsmProduct.status === 'active'
                                      ? 'Active'
                                      : dsmProduct.status === 'paused'
                                        ? 'Paused'
                                        : 'Non-Active'
                                    : ''}
                                </p>
                                <div className="ml-2">
                                  <select
                                    className="w-full text-xs"
                                    onChange={(event) => handleSelectChange(event, dsmProduct.product.assortimed_pid)}
                                  >
                                    <option value="all">Select...</option>
                                    {status.map((option, index) => (
                                      <option key={index} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>

          <div className="mx-auto my-12 flex w-full flex-col justify-between md:w-[60%] md:flex-row">
            <button
              onClick={handleSaveForm}
              className="text-md m-auto my-2  rounded-lg bg-custom-blue px-28 py-2 font-semibold text-white hover:bg-blue-700 "
            >
              Save Changes
            </button>
            <button
              onClick={handleCancel}
              className="text-md m-auto my-2 rounded-lg bg-amber-500 px-28 py-2 font-semibold text-white hover:bg-amber-400"
            >
              Cancel
            </button>
          </div>
        </>
      )}

      { successMessage ? 
          <p className="mx-auto text-center w-10/12 p-4 text-base mt-4 font-bold text-custom-blue ">
              You have successfully updated your DSM!
            </p>
        : null
      }

    </>
  );
}
