import React, { useState, useEffect } from 'react';
import axios from 'axios';


import { IoIosArrowDown } from 'react-icons/io';

const DSMProductItem = ({ product }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [productRecord, setProductRecord] = useState(null);
  
  const accessToken = localStorage.getItem('accessToken');

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
 

  useEffect(() => {
    const getProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/product-detail/${product.product}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        
        //console.log("Orders Data: " + response.data);
  
        if (response.status >= 200 && response.status < 300) {
          setProductRecord(response.data);
        } else {
          console.error(`Error retrieving data: ${response.statusText}`);
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getProduct();
  });

  

  if (!product || !product.id || !productRecord) {
    return null; // or some fallback UI
  }


  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));
  const imgUrl = imgPaths[productRecord.assortimed_pid][0];

  return (
    <>
      <div className="relative mt-4 flex w-full flex-row rounded border bg-white p-6 shadow">
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          <div className="mr-2 flex w-full flex-col ">
            <img
              src={imgUrl}
              alt="description_of_image"
              className="h-20 w-20 object-cover"
            />
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Category</p>
            <span className="text-sm text-gray-500">{productRecord.category}</span>
          </div>
          <div className="mt-3 flex w-full flex-col">
            <p className="mb-2 text-xs font-bold">Price</p>
            <span className="text-sm text-gray-500">€ {productRecord.sales_price_c1}</span>
            {/* <p className="mb-2 text-xs font-bold text-gray-500">(Vat incl.)</p> */}
          </div>
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Stock Lower Limit</p>
            <span className="text-sm text-gray-500">{product.lower_spec_stock}</span>
          </div>
        </div>
        
        <div className="mt-1 flex w-full flex-col justify-between p-1 sm:flex-row sm:items-center sm:justify-between">
          
          
          <div className="mr-2 mt-3 flex w-full flex-col ">
            <p className="mb-2 text-xs font-bold">Stock Upper Limit</p>
            <span className="text-sm text-gray-500">{product.upper_spec_stock}</span>
          </div>
          <div className="flex w-full flex-col mt-3">
            <p className="mb-2 text-xs font-bold">Status Update Date</p>
            <span className="text-sm text-gray-500">{
                product.product_status === 'active'? product.product_active_date.slice(0, 10)
                : (
                  product.product_status === 'paused'? product.product_paused_date.slice(0, 10)
                  : product.product_non_active_date.slice(0, 10)
                )}</span>
          </div>
          {/** Status Btn Section */}
          <div className="mr-12 mt-3 flex w-full flex-row space-x-4 p-1 text-sm font-bold">
            {product.product_status === 'active' && (
              <button className="w-75 rounded-full border-2 bg-green-400 p-2 text-white hover:bg-green-500">
                Active
              </button>
            )}
            {product.product_status === 'non_active' && (
              <button className="w-75 rounded-full border-2 bg-red-400 p-2 text-white hover:bg-red-500">
                Non Active
              </button>
            )}
            {product.product_status === 'paused' && (
              <button className="w-75 rounded-full border-2 bg-orange-300 p-2 text-white hover:bg-orange-400">
                Paused
              </button>
            )}
          </div>
          
        </div>
      </div>
    </>
  );
};

export default DSMProductItem;
