import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import { Row, Col } from 'react-grid-system';
import Pagination from 'react-js-pagination';
import { toast, ToastContainer } from 'react-toastify';

// Icon Imports
import { IoMdSearch } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import PopupCreatePackage from './PopupCreatePackage';

// Local Imports
import styles from '../../components/UShop/UserShopSlider.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};
export default function CopyPackage({ packList }) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedPackOption, setSelectedPackOption] = useState(null);
  const [productNames, setProductNames] = useState([]);

  const handleEditForm = () => {
    if (selectedPack) {
      setShowEditForm(!showEditForm);
    }
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  // Products Hangling Section (MiniShop)
  const [searchQuery, setSearchQuery] = useState('');
  const storedProducts = JSON.parse(localStorage.getItem('products')) || [];
  const filteredProducts = storedProducts.filter(
    (product) =>
      product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const uniqueCategories = [
    ...new Set(storedProducts.map((product) => product.category)),
  ];
  const uniqueSubCategory1 = [
    ...new Set(storedProducts.map((product) => product.sub_category_1)),
  ];
  const uniqueSubCategory2 = [
    ...new Set(storedProducts.map((product) => product.sub_category_2)),
  ];
  const uniqueBrand = [
    ...new Set(storedProducts.map((product) => product.brand)),
  ];

  const options = [
    { value: 'All', label: 'All' },
    ...uniqueCategories.map((item) => ({ value: item, label: item })),
  ];

  const [activeItem, setActiveItem] = useState('All');

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const [addedProducts, setAddedProducts] = useState([]);
  const handleAddProduct = (product) => {
    const foundProduct = storedProducts.find(p => product.id === p.id);
    const newProduct = {
      id: foundProduct.id,
      photo: imgPaths[foundProduct.assortimed_pid][0],
      name: product.name,
      quantity: 1,
      sales_price_c1: product.sales_price_c1,
      category: foundProduct.category,
    };
    setAddedProducts([...addedProducts, newProduct]);
  };

  
  // Handling Searching in Search Box by product name
  const searchedProduct =
    searchQuery.length > 0
      ? storedProducts.filter((product) =>
          `${product.name}`.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : storedProducts;
  
  // Handling Deleting item from the addedProducts List
  const handleDeleteProduct = (product) => {
    setAddedProducts(addedProducts.filter((p) => p !== product));
  };

  // Handling fourm Visibility Change
  const [showForm, setShowForm] = useState(false);
  const handleOpenForm = () => {
    setShowForm(true);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };

  // refresh component to udate the products in MiniShop
  useEffect(() => {}, [searchQuery, filteredProducts]);
  const displayedProducts = filteredProducts;

  // DSM User Handling Section
  // const user = JSON.parse(localStorage.getItem('user'));
  // const user_pack = Array.isArray(user.package_data) ? user.package_data : [];
  const user_pack = packList ? packList : [];

  const handleProductSelect = (selectedPackOption) => {
    setSelectedPackOption(selectedPackOption);
    const packData = user_pack.find(
      (pack) => pack.package_name === selectedPackOption.value,
    );
    setSelectedPack(packData);
    //console.log("Selected Package Data: ", packData);

    const products =
    packData && packData.products
      ? packData.products.map((packageProduct) => {
          const foundProduct = storedProducts.find(product => packageProduct.product === product.id);
          //console.log("Package Product:", packageProduct);
          //console.log("Found Product:", foundProduct);
          return {
            product: foundProduct,
            value: packageProduct.id,
            label: packageProduct.id,
            status: packageProduct.product_status,
            quantity: packageProduct.quantity_per_product,
            price: packageProduct.total_price_per_product,
          };
        })
      : [];
    
    
    const packProducts =
    packData && packData.products
      ? packData.products.map((packageProduct) => {
          const foundProduct = storedProducts.find(product => packageProduct.product === product.id);
          //console.log("Package Product:", packageProduct);
          //console.log("Found Product:", foundProduct);
          return {
            id: packageProduct.product,
            category: foundProduct.category,
            name: foundProduct.name,
            quantity: packageProduct.quantity_per_product,
            sales_price_c1: foundProduct.sales_price_c1,
            photo: imgPaths[foundProduct.assortimed_pid][0]
          };
        })
      : [];
    
    setProductNames(products);
    setAddedProducts(packProducts);
    //console.log("Added Package Products: " + addedProducts);
  };

  const packOptions = user_pack.map((pack) => ({
    value: pack.package_name,
    label: pack.package_name,
  }));

  const status = [
    { value: 'active', label: 'Active' },
    { value: 'non_active', label: 'Non Active' },
    { value: 'paused', label: 'Paused' },
  ];


  // handle Product Card Display and Option
  const handleIncrement = (product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.id === product.id ? { ...p, quantity: p.quantity + 1, id: product.id  } : p,
      ),
    );
  };

  const handleDecrement = (product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.id === product.id && p.quantity > 1
          ? { ...p, quantity: p.quantity - 1, id: product.id  }
          : p,
      ),
    );
  };


  //Pagination Controls
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 6;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, searchedProduct.length);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Showing Sucess Message after clicing on the Proceed to Review button
  const showToast = (message) => {
      toast.success(message, {
        autoClose: 3000,
      });
    };
  const showFail = (message) => {
      toast.error(message, {
        autoClose: 3000,
      });
    };
  
  // Handling post sucess
  const handleCreationSucess = () => {
      setAddedProducts([]);
    }
  


  return (
    <>
      {/** Choosing a Package to Copy */}
      {!showEditForm && (
        <div className="m-auto my-auto w-[90%] text-center">
          <h2 className="mx-auto mt-11  font-sans text-2xl font-semibold text-custom-blue">
            Choose a Package to Copy
          </h2>
          <p className="mx-auto w-8/12 p-4 text-sm font-bold text-red-500 ">
            Copying a Package will allow you to create new Package out of your existing
            Package with any modifications you require to it.
          </p>

          <div className="mx-auto mb-12 flex w-[90%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
            <Select
              options={packOptions}
              styles={customStyles}
              className="m-auto w-full"
              placeholder="Choose ..."
              onChange={handleProductSelect}
              value={selectedPackOption}
            />
            {showEditForm ? null : (
              <button
                onClick={handleEditForm}
                disabled={!selectedPack}
                className={`${selectedPack ? 'text-md whitespace-nowrap rounded-lg bg-custom-blue px-20 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue' : 'text-md cursor-not-allowed whitespace-nowrap rounded-lg bg-gray-300 px-28 py-1 font-semibold text-gray-500'} `}
              >
                Start Copying ...
              </button>
            )}
          </div>
        </div>
      )}

      {/**Title after choosing a Package */}
      <ToastContainer className="left-[40%]" />
      {showEditForm && (
        <div className="mx-auto mb-8 mt-8 flex w-[90%] items-center rounded p-2 text-center shadow">
          <div className="mx-auto flex w-2/3 flex-col items-center justify-center">
            <h2 className="font-sans text-2xl font-semibold text-black">
              {selectedPack ? selectedPack.package_name : ''}
            </h2>
            <p className="mt-2 p-2 text-sm font-bold text-red-500">
              Create new Package by modifying
            </p>
          </div>

          <button
            onClick={handleCancel}
            className="text-md m-auto my-2 rounded-lg bg-amber-500 px-20 py-2 font-semibold text-white hover:bg-amber-400"
          >
            Cancel
          </button>
        </div>
      )}

      {/** Add Package Like Interface */}
      {showEditForm && (
        <div className="m-auto text-center shadow-lg">
          {/** Shop Page */}
          
          {/**Filtration section Orders Focus */}
          <div className="w-full border-b-2 border-gray-200 bg-white p-2">
            {/* On small screens, display the select dropdown */}
            <div className="block lg:hidden">
              <Select
                options={options}
                value={{ value: activeItem, label: activeItem }}
                onChange={(selectedOption) =>
                  handleItemClick(selectedOption.value)
                }
              />
            </div>

            {/* On larger screens, display the list view */}
            <ul className="text-md flex hidden w-full font-bold lg:flex">
              <li key="all" className="flex-1">
                <a
                  className={`block rounded border-b-2 border-transparent px-4 py-1 text-center 
          ${activeItem === 'All' ? 'font-bold text-gray-700' : 'text-gray-400'} 
           hover:font-bold hover:text-gray-700`}
                  onClick={() => handleItemClick('All')}
                  href="#"
                >
                  All
                </a>
              </li>
              {uniqueCategories.map((item, index) => (
                <li key={index} className="flex-1">
                  <a
                    to="#"
                    className={`block cursor-pointer rounded border-b-2 border-transparent px-4 py-1 text-center
          ${activeItem === item ? 'font-bold text-gray-700' : 'text-gray-400'} 
          hover:font-bold hover:text-gray-700`}
                    onClick={() => handleItemClick(item)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/**Filtration section Apply filter button */}
          <div className="flex w-full flex-wrap items-center justify-between border-b-2 border-gray-300 bg-white p-3 shadow-md">
            <div className="flex w-full justify-around md:w-[46%]">
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
                  <option value="">Sub Category 1</option>
                  {uniqueSubCategory1.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
                  <option value="">Sub Category 2</option>
                  {uniqueSubCategory2.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex w-full justify-around md:w-[44%]">
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <select className="w-full rounded border bg-gray-200 p-1 px-2 outline-none">
                  <option value="">Brand</option>
                  {uniqueBrand.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <input
                  type="search"
                  aria-label="Search"
                  className="form-control me-2 w-full rounded border bg-gray-200 p-1  px-2 outline-none"
                  placeholder="Search Product"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
              <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto">
                Filter
              </button>
            </div>
          </div>

          {/** Side Choosen Product List */}
          <div className="flex flex-col sm:flex sm:flex-row">
            <aside
          className="sm:md-2 mb-6 grid w-full grid-rows-3 sm:w-[36%]"
              style={{ gridTemplateRows: '84px 670px 50px' }}
            >
          <div>
            <div className="bg-[#c4714e] px-2 py-3 text-center text-base font-bold text-white">
              List of Package Items/Products
            </div>
            <div className="bg-gray-100 p-2 text-center text-xs font-semibold text-neutral-400/75">
              <p>Click + to add a product</p>
            </div>
          </div>

            {/** Listing Product Cards */}
            {addedProducts.length > 0 ? (
            <div className="m-1 w-full overflow-y-auto">
              <ul>
                {addedProducts.map((packageProduct, index) => (
                  <li key={index} className="mt-6 flex justify-center">
                  {/** Product Card */}
                  <div className="hover shadow relative m-1 w-[90%] flex-col items-center justify-center overflow-hidden rounded border bg-white p-1">
                        <div className="flex h-full w-full flex-row items-center justify-between">
                          <span
                            className="absolute right-2 top-2 cursor-pointer bg-white"
                            onClick={() => handleDeleteProduct(packageProduct)}
                          >
                            <RiDeleteBin5Line className="text-red-500 " />
                          </span>
                          <div className="mr-2 flex w-2/5 h-[100px]">
                            <img
                              src={packageProduct.photo}
                              alt="Product"
                              className="w-full flex-grow object-cover"
                            />
                          </div>
                          <div className="mb-3 mr-2 flex w-3/5 flex-col items-center">
                            <p className=" my-3 text-center text-sm font-bold text-custom-blue">
                              {packageProduct.name}
                            </p>
                            <div className="flex w-full justify-around text-xs font-semibold">
                              <div className="flex items-center rounded-md bg-[#f1f1f1] text-sm font-semibold">
                                <button className="ml-3 mr-1 p-1 text-2xl text-custom-blue" onClick={() => handleDecrement(packageProduct)}>{' '}-{' '}</button>
                                <span className="mx-1 p-1">{packageProduct.quantity}</span>
                                <button className="ml-1 mr-3 p-1 text-xl text-custom-blue" onClick={() => handleIncrement(packageProduct)}>{' '}+{' '}</button>
                              </div>

                              <div className="flex items-center justify-center">
                                <h4 className="mr-2 text-sm font-bold text-custom-blue">
                                  € {packageProduct.price}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        {/** End Product Card */}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
                <div className="m-auto text-gray-400">
                  <div className="m-auto flex h-20 w-20 items-center  justify-center rounded-full bg-gray-200 ">
                    <BsSearch className=" text-4xl " />
                  </div>
                </div>
              )}

              <div className="mt-2 flex w-full justify-center">
                <button
                  onClick={handleOpenForm}
                  className="text-md w-full rounded-lg bg-custom-blue py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue"
                >
                  <IoMdSearch className="mr-3 inline-block h-6 w-6 align-middle" />
                  Proceed to Review
                </button>
                {showForm && (
                  <PopupCreatePackage
                    onClose={handleCloseForm}
                    addedProducts={addedProducts}
                    packData={selectedPack}
                    showToast={showToast}
                    showFail={showFail}
                    handleSucess={handleCreationSucess}
                  />
                )}
              </div>
            </aside>

            <div className="w-full overflow-hidden bg-custom-dsm sm:w-[64%]">
          <div className="h-20 w-full bg-[#ffe8de]"></div>
          <Row className="mb-8 mt-4">
            {Array.isArray(storedProducts) && storedProducts.length > 0 ? (
              searchedProduct.slice(startIndex, endIndex).map((card, index) => (
                <Col lg={4} md={6} sm={8} xs={12} key={index}>
                  <Card key={index} className={styles.card_body}>
                    <div className="h-1/2 w-full">
                      <Card.Img
                        variant="top"
                        src={imgPaths[card.assortimed_pid][0]}
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <Card.Body className="flex flex-grow flex-col justify-between p-4">
                      <div>
                        <Card.Title className="text-base font-bold text-center">
                          {card.category}
                        </Card.Title>
                        <Card.Text className="text-sm font-semibold text-center">
                          {card.name}
                        </Card.Text>
                      </div>
                      <div className="flex cursor-pointer justify-between">
                      <h4 className="mr-2 text-custom-blue font-bold">
                      € {card.sales_price_c1}
                        </h4>
                        <MdAdd
                          className="right-0 rounded-full bg-custom-blue p-1 text-2xl text-white"
                          onClick={() =>
                            handleAddProduct({
                              photo: imgPaths[card.assortimed_pid][0],
                              name: card.name,
                              sales_price_c1: card.sales_price_c1,
                              category: card.category,
                              id: card.id,
                            })
                          }
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="card-title">No products found.</p>
            )}
          </Row>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={storedProducts.length}
            pageRangeDisplayed={4}
            onChange={handlePageChange}
            className="my-10"
          />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
