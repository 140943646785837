import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";

import Homepage from "./pages/Home/Homepage";
import LandingPage from "./pages/Home/LandingPage";
import PageNotFound from "./pages/PageNotFound";

import LoginPage from "./pages/Sign/LoginPage";
import SignUp from "./pages/Sign/SignUp";
import OTPPage from "./pages/Sign/OTPPage";
import ForgetPage from "./pages/Sign/ForgetPage";
import ResetNPage from "./pages/Sign/ResetNPage";
import ResetPage from "./pages/Sign/ResetPage";
import SuccessRPage from "./pages/Sign/SuccessRPage";
import ConfirmRPage from "./pages/Sign/ConfirmRPage";

import DSM from "./pages/Info/DSM";
import PackageInfo from "./pages/Info/Package";
import ClientSuite from "./pages/Info/ClientSuite";
import Contact from "./pages/Info/Contact";
import AboutUs from "./pages/Info/AboutUs";

import WebShop from "./pages/Shop/WebShop";
import ShopListing from "./pages/Shop/ShopListing";

import Career from "./pages/Career/Career";
import JobDetails from "./pages/Career/JobDetails";
import CareerProducts from "./pages/Career/CareerListing";
import JobGrid from "./components/Career/JobGrid";


import UserShopList from "./pages/UShop/UserShopList";
import ReviewProduct from "./pages/UShop/ReviewProduct";

import Basket from "./pages/basket/Basket";
import ProfileShipping from "./pages/basket/ProfileShipping";
import CompleteOrder from "./pages/basket/CompleteOrder";
import OrderCompleted from "./pages/basket/OrderCompleted";


import ClientBoard from "./dashboard/ClientSuite";
import DashboardPage from "./dashboard/DashboardPage";



import AdminBoard from "./adminboard/AdminSuite";




import CareerContextProvider from "./context/CareerContextProvider";
import ProductContextProvider from "./context/ProductContextProvider";
import FilterContextProvider from "./context/FilterContextProvider";
import SubListingContextProvider from "./context/SubListingContextProvider";


// Style Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




function App() {
  const [data, setData] = React.useState(null);
  const [choosenProducts, setChoosenProducts] = React.useState([]);
  const [subListings, setSubListings] = React.useState([]);

  return (
    <>
      <BrowserRouter>
      <CareerContextProvider value={{ data, setData }}>
      <ProductContextProvider value={{ data, setData }}>
      <FilterContextProvider value={{ choosenProducts, setChoosenProducts }}>
      <SubListingContextProvider value={{ subListings, setSubListings }}>
        <Routes>
          <Route index element={<Homepage />} />
          <Route path="land" element={<LandingPage />} />
          <Route path="dsm" element={<DSM />} />
          <Route path="package" element={<PackageInfo />} />
          <Route path="webshop" element={<WebShop />} />
          <Route path="clientinfo" element={<ClientSuite />} />
          <Route path="career" element={<Career />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="contact" element={<Contact />} />

          {/* ------- Sign in and Registration Processes ----------*/}
          <Route path="login" element={<LoginPage />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route path="otp" element={<OTPPage />} />
          <Route path="forget" element={<ForgetPage />} />
          <Route path="resetn" element={<ResetNPage />} />
          <Route path="reset/:uid/:token" element={<ResetPage />} />
          <Route path="successr" element={<SuccessRPage />} />
          <Route path="confirmr" element={<ConfirmRPage />} />
          
          
          <Route path="basket" element={<Basket/>}/>
          <Route path="profileshipping" element={<ProfileShipping/>}/>
          <Route path="completeorder" element={<CompleteOrder/>}/>
          <Route path="ordercompleted" element={<OrderCompleted/>}/>


          {/* -------detailed views for visitor ----------*/}
          <Route path="careerlisting" element={<CareerProducts/>} />
          <Route path="jobdetails" element={<JobDetails/>} />
          <Route path="jobgrid" element={<JobGrid />} />
          <Route path="shoplisting" element={<ShopListing/>} />

          {/* -------detailed views for users ----------*/}
          <Route path="usershoplist" element={<UserShopList/>} />
          <Route path="reviewproduct" element={<ReviewProduct/>} />

          {/* ------- clientsuite ----------*/}
          <Route path="clientsuite" element={<ClientBoard />} />
          <Route path='clientsuite/dashboard/' element={<DashboardPage />} />
          
          <Route path="adminsuite" element={<AdminBoard />} />
          

          {/* ------- default views ----------*/}
          <Route path="*" element={<PageNotFound />} />

        </Routes>
        </SubListingContextProvider>
        </FilterContextProvider>
        </ProductContextProvider>
        </CareerContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
