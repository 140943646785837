import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Style Attributes
import { BiSolidAddToQueue } from 'react-icons/bi';
import { LuClipboardEdit } from 'react-icons/lu';

// Tab Content
import NewPackage from './package/NewPackage';
import EditPackage from './package/EditPackage';
import CopyPackage from './package/CopyPackage';

export default function PackagePage() {
  const [fillActive, setFillActive] = useState('AddPackage');

  const handleSelectChange = (event) => {
    setFillActive(event.target.value);
  };


    const currentUser =  JSON.parse(localStorage.getItem('user'))['user_data'];
    const accessToken = localStorage.getItem('accessToken');
    const [packageData, setPackageData] = useState([]);
    
    useEffect(() => {
      const getPackage = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/package/user/${currentUser.id}/`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          
          //console.log("Orders Data: " + response.data);
    
          if (response.status >= 200 && response.status < 300) {
            setPackageData(response.data);
          } else {
            console.error(`Error retrieving data: ${response.statusText}`);
          }
        } catch (error) {
          console.error(`Error retrieving data: ${error}`);
        }
      };
    
      getPackage();
    }, [currentUser.id, accessToken]);

  return (
    <>
      <div className="sm:hidden">
        <select
          id="tabs"
          className="block w-full border border-gray-300 bg-gray-50  p-2.5 text-sm text-gray-900"
          onChange={handleSelectChange}
        >
          <option value="AddPackage"><BiSolidAddToQueue />Add Package</option>
          <option value="EditPackage"><LuClipboardEdit />Edit Package</option>
          <option value="EditPackage"><LuClipboardEdit/>Copy Package</option>
        </select>
      </div>
      <ul className="mt-0 hidden w-full rounded-lg text-center text-lg font-semibold text-gray-500 shadow sm:flex">
        <li className="w-full">
          <a
            href="#"
            onClick={() => setFillActive('AddPackage')}
            className={`inline-block w-full border-b-4 p-3 ${fillActive === 'AddPackage' ? 'border-custom-green bg-gray-100 font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100'} focus:outline-none`}
          >
            <span
              className={` ${fillActive === 'AddPackage' ? 'text-custom-green' : 'text-gray-500 hover:text-custom-green'}`}
            >
              <BiSolidAddToQueue className="mr-3 inline-block h-6 w-6 align-middle" />
            </span>
            <span
              className={`inline-block ${fillActive === 'AddPackage' ? 'text-custom-blue' : 'hover:text-custom-gray-900 text-gray-500'}`}
            >
              Add Package
            </span>
          </a>
        </li>
        <li className="w-full">
          <a
            href="#"
            onClick={() => setFillActive('EditPackage')}
            className={`inline-block w-full border-b-4 p-3 ${fillActive === 'EditPackage' ? 'border-custom-green bg-gray-100 font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100'} focus:outline-none`}
          >
            <span
              className={` ${fillActive === 'EditPackage' ? 'text-custom-green' : 'text-gray-500 hover:text-custom-green'}`}
            >
              <LuClipboardEdit className="mr-3 inline-block h-6 w-6 align-middle" />
            </span>
            <span
              className={`inline-block ${fillActive === 'EditPackage' ? 'text-custom-blue' : 'hover:text-custom-gray-900 text-gray-500'}`}
            >
              Edit Package
            </span>
          </a>
        </li>
        <li className="w-full">
          <a
            href="#"
            onClick={() => setFillActive('CopyPackage')}
            className={`inline-block w-full border-b-4 p-3 ${fillActive === 'CopyPackage' ? 'border-custom-green bg-gray-100 font-bold' : 'border-gray-200 hover:border-custom-green hover:bg-gray-100'} focus:outline-none`}
          >
            <span
              className={` ${fillActive === 'CopyPackage' ? 'text-custom-green' : 'text-gray-500 hover:text-custom-green'}`}
            >
              <LuClipboardEdit className="mr-3 inline-block h-6 w-6 align-middle" />
            </span>
            <span
              className={`inline-block ${fillActive === 'CopyPackage' ? 'text-custom-blue' : 'hover:text-custom-gray-900 text-gray-500'}`}
            >
              Copy Package
            </span>
          </a>
        </li>
      </ul>
      <div style={{ display: fillActive === 'AddPackage' ? 'block' : 'none' }}>
        <NewPackage />
      </div>
      <div style={{ display: fillActive === 'EditPackage' ? 'block' : 'none' }}>
        <EditPackage packList={ packageData } />
      </div>
      <div style={{ display: fillActive === 'CopyPackage' ? 'block' : 'none' }}>
        <CopyPackage packList={ packageData } />
      </div>
    </>
  );
}
