import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import axios from 'axios';

// Icons Imports
import { FaCalendar } from 'react-icons/fa';


// Local Imports
import InvoiceItem from './InvoiceItem';


const InvoicesTable = () => {
  // Pagination Controls
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };


  // Retrieve invoices of the current user
  const currentUser =  JSON.parse(localStorage.getItem('user'))['user_data'];
  const accessToken = localStorage.getItem('accessToken');
  const [invoices, setInvoices] = useState([]);
  const [filteredIvoices, setFilteredInvoices] = useState([]);
  const [callAPI, setCallAPI] = useState(false);

  useEffect(() => {
    const getInvoice = async () => {
      //// console.log("Inside Invoice");
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/orders/invoices/user/${currentUser.id}/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        
        // setFilteredInvoices(response.data);
        // // console.log("invoices Data: " + response.data);
  
        if (response.status >= 200 && response.status < 300) {
          // console.log("invoices Retrived Successfully!!");
          setInvoices(response.data);
          setFilteredInvoices(response.data);
          // // console.log("invoices Data: " + response.data);
          setCallAPI(true);
        }
      } catch (error) {
        console.error(`Error retrieving data: ${error}`);
      }
    };
  
    getInvoice();
  }, [currentUser.id, accessToken]);


  // Click Filter Button
  const [useEffectTrigger, setUseEffectTrigger] = useState(0);
  
  const handleFilter = () => {
    setUseEffectTrigger(prev => prev + 1);
  };
  
    // invoice DropLists filter
  const [invoiceNumbers, setInvoiceNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(null);

  const handleNumber = (selectedOption) => {
    setSelectedNumber(selectedOption);
  };


  // invoice DropLists filter
  const invoiceStatus = [
    { value: 'all', label: 'All Invoices' },
    { value: 'active', label: 'ACTIVE Invoices' },
    { value: 'completed', label: 'Completed Invoices' },
    { value: 'cancelled', label: 'Cancelled Invoices' },
    { value: 'returned', label: 'Returned Invoices' },
  ];
  //const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
    
  const handleStatus = (selectedOption) => {
      setSelectedStatus(selectedOption);
    };

  // Date Range Filter
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  const toggleDatePicker = () => setDatePickerVisible(!isDatePickerVisible);
  
  // Multiple invoice Type filter
  // Filtering Functions
  useEffect(() => {
    const invoiceNumbers = invoices.map(invoice => invoice.invoice_number);
    const formattedinvoiceNumbers = invoiceNumbers.map(invoiceNumber => ({
      value: invoiceNumber,
      label: invoiceNumber.toString(),
    }));
    // // console.log("InvoiceNumbers: ", invoiceNumbers);
    const defaultValue = { value: 'default', label: 'All Invoices' };
    formattedinvoiceNumbers.unshift(defaultValue);
    setInvoiceNumbers(formattedinvoiceNumbers);

  }, [filteredIvoices, invoices, useEffectTrigger]);


  // invoice Status Filter
  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus.value === 'default') {
        setFilteredInvoices(invoices);
        setSelectedNumber(null);
        setSelectedStatus('default');
        setStartDate(null);
        setEndDate(null);
      } else {
          // Mapping of activeItem to invoice_status values
          const statusMap = {
            'all': ['in_progress', 'in_wharehouse', 'on_the_road', 'waiting', 'delayed', 'recieved', 'delivered', 'completed', 'parthly_delivered'],
            'active': ['in_progress', 'in_wharehouse', 'on_the_road', 'waiting', 'delayed', 'cancelled', 'missing', 'returned', 'partially_returned'],
            'completed': ['recieved', 'delivered', 'completed', 'parthly_delivered'],
            'cancelled': ['cancelled', 'missing'],
            'returned': ['returned', 'partially_returned']
          };
          // Determine the statuses
          const statusesToFilterBy = statusMap[selectedStatus.value];
        const filtered = invoices.filter(invoice => statusesToFilterBy.includes(invoice.invoice_status));
        setFilteredInvoices(filtered);
      }
    }
  }, [selectedStatus, filteredIvoices, invoices]);
  
  
  // Invoice Number Filter
  useEffect(() => {
    if (selectedNumber) {
      if (selectedNumber.value === 'default') {
        setFilteredInvoices(invoices);
        setSelectedNumber(null);
        setSelectedStatus(null);
        setStartDate(null);
        setEndDate(null);
      } else {
        // Filter based on the selected invoice number
        const numFilteredIvoices = filteredIvoices.filter(invoice => 
          invoice.invoice_number === selectedNumber.value
        );
        setFilteredInvoices(numFilteredIvoices);
      }
    }
  }, [selectedNumber, filteredIvoices, invoices, useEffectTrigger]);


  // Date Range Filter
  useEffect(() => {
      if (startDate === '' || endDate === '') {
        // Additional date filtering
        const numFilteredIvoices = filteredIvoices.filter(invoice => {
          const invoiceDate = new Date(invoice.invoice_date);
  
          if (startDate && endDate) {
            return invoiceDate >= startDate && invoiceDate <= endDate;
          } else if (startDate) {
            return invoiceDate >= startDate;
          } else if (endDate) {
            return invoiceDate <= endDate;
          }
          return true; 
        });
        setFilteredInvoices(numFilteredIvoices);
      }
    
  }, [startDate, endDate,  filteredIvoices, useEffectTrigger]); 



  return (
    <>
      {/* Filtration section Apply filter button */}
      <div className=" flex w-full flex-wrap items-center justify-center binvoice-b-2 binvoice-gray-300 bg-white p-3 shadow-md">
        <div className="flex w-full justify-around md:w-[46%]">
          {/** Invoice Number Filtration */}
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <Select
            className="w-full"
            options={invoiceNumbers}
            placeholder="Invoice Number"
            onChange={handleNumber}
            theme={(theme) => ({
              ...theme,
              binvoiceRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'gray-400',
                primary: 'binvoice',
              },
            })}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: 'gray-600',
                  fontStyle: 'italic',
                  fontWeight: 'semibold',
                };
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? 'lightblue' : 'white',
                color: state.isFocused ? 'black' : 'gray',
                cursor: 'pointer',
              }),
            }}
          />
          </div>

          {/** Date Filtration */}
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <button className="w-full h-full text-left pl-3 pr-3 py-1 text-base binvoice-gray-300 binvoice-1 focus:outline-none focus:ring-indigo-500 italic text-gray-800 flex justify-between items-center" onClick={toggleDatePicker}>
            <span>Select Date Range</span>
            <FaCalendar className="text-slate-400 text-right right-0"/>
          </button>
          {isDatePickerVisible && (
            <div className="absolute z-50 bg-white binvoice-2 binvoice-gray-300 p-4">
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                  setDatePickerVisible(false);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </div>
          )}
          </div>
        </div>

        {/** invoice Status Filtration */}
        <div className="flex w-full justify-around md:w-[35%]">
          <div className="mb-2 w-full flex-1 px-2 md:mb-0">
          <Select
            className="w-full"
            options={invoiceStatus}
            placeholder="invoice Status"
            onChange={handleStatus}
            theme={(theme) => ({
              ...theme,
              binvoiceRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'gray-400',
                primary: 'binvoice',
              },
            })}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: 'gray-600',
                  fontStyle: 'italic',
                  fontWeight: 'semibold',
                };
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? 'lightblue' : 'white',
                color: state.isFocused ? 'black' : 'gray',
                cursor: 'pointer',
              }),
            }}
          />
          </div>
          <div className="mb-2 flex w-1/2 justify-center px-2 md:mb-0 md:w-auto">
          <button className="text-md w-full rounded-lg text-custom-blue px-6 py-1 font-semibold binvoice-2 binvoice-custom-blue hover:bg-custom-blue hover:text-white sm:w-auto"
          onClick={handleFilter}>
            Filter
          </button>
          </div>
        </div>
      </div>

      {/**Display User / Filtered invoices */}
      <div className="w-100 container mx-auto overflow-auto pb-8">
        {filteredIvoices &&
          filteredIvoices
            .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
            .map((bill) =>
              bill ? <InvoiceItem key={bill.number} bill={bill} /> : null,
            )}
      </div>

      { filteredIvoices.length === 0 ?
       <p className="w-100 self-center text-center font-bold text-gray-600 m-4 mx-auto">There are no 
              <span className='text-custom-blue'> Invoices </span> 
              that matches your search!</p> : null}

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filteredIvoices.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

export default InvoicesTable;
