import React, { useState } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

// Icon Imports
import {
  MdApartment,
  MdFormatListNumbered,
  MdOutlineDateRange,
  MdOutlineMotionPhotosOff,
} from 'react-icons/md';
import { GrStatusGood } from 'react-icons/gr';
import { IoRepeat } from 'react-icons/io5';
import {
  TbBasketPause,
  TbTruckDelivery,
  TbFileDescription,
} from 'react-icons/tb';
import { IoMdRadioButtonOn } from 'react-icons/io';
import { FaEuroSign } from 'react-icons/fa6';


// Styling Selects
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};




export default function EditPackage({ packList }) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [packageStatus, setPackageStatus] = useState('active');
  const [productNames, setProductNames] = useState([]);
  // const [productStatus, setProductStatus] = useState('active');

  // Extracting DSM Data
  // const user = JSON.parse(localStorage.getItem('user'));
  // const user_pack = Array.isArray(user.package_data) ? user.package_data : [];
  const user_pack = packList ? packList : [];
  
  // Set Products Info
  const storedProducts = JSON.parse(localStorage.getItem('products'));
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  // Selected Package from DropList Module "Select"
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageOption, setSelectedPackageOption] = useState(null);
  // Updating Package Status
  const [packStatus, setPackStatus] = useState(null);
  const [statusLabel, setStatusLabel] = useState('');

  // Set Data upon Package Selection
  const handleProductSelect = (selectedPackageOption) => {
    setSelectedPackageOption(selectedPackageOption);
    const packageData = user_pack.find(
      (pack) => pack.package_name === selectedPackageOption.value,
    );
    setSelectedPackage(packageData);
    setStatusLabel(packageData.package_status.charAt(0).toUpperCase() + packageData.package_status.slice(1));

    const products =
      packageData && packageData.products
        ? packageData.products.map((packageProduct) => ({
              product: storedProducts.find(product => packageProduct.product === product.id),
              value: packageProduct.id,
              label: packageProduct.id,
              status: packageProduct.product_status,
              quantity: packageProduct.quantity_per_product,
              price: packageProduct.total_price_per_product,
            }),
          )
        : [];
    //console.log("package Products : " + JSON.stringify(products));
    setProductNames(products);
  };


  // Handling Editing Fourms appearnce and status
  const [successMessage, setSuccessMessage] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const handleEditForm = () => {
    if (selectedPackage) {
      setShowEditForm(!showEditForm);
    }
    setSuccessMessage(null);
    setFailureMessage(null);
  };


  // Handling Save Package Editing
  const handleSaveForm = async () => {
    if (packStatus) {
      try {
        //console.log("Package Status : " + packStatus);
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/products/package/update/${selectedPackage.id}/`, 
          {
            package_status: packStatus,
          });

          if (response.status >= 200 && response.status < 300) {
              setSuccessMessage('Package updated successfully!');
              setShowEditForm(false);
              setSelectedPackageOption(null);
              setSelectedPackage(null);
              setSelectedProductOption(null);
              setShowProductData(false);
              setProductNames([]);
          } else {
              setFailureMessage('Error updating package!');
          }
        } catch (error) {
              //console.error('Error uploading file:', error);
              //setErrorMessage('Error uploading file');
              setFailureMessage('Error updating package!');
        }
      } else {
        setFailureMessage('Please select new status to update!');
      }
  };

  // Handling Show Package Info
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [showProductData, setShowProductData] = useState(false);
  const HandleShowData = (selectedProductOption) => {
    setSelectedProductOption(selectedProductOption);
    setShowProductData(true);
  };

  // Handling Cancelling Edit Package
  const handleCancel = () => {
    setShowEditForm(false);
    setFailureMessage(null);
    setSuccessMessage(null);
  };


  const options = user_pack.map((pack) => ({
    value: pack.package_name,
    label: pack.package_name,
  }));

  const status = [
    { value: 'active', label: 'Active' },
    { value: 'non_active', label: ' Non Active' },
    { value: 'paused', label: 'Paused' },
  ];


  const rows = [
    {
      icon: <MdApartment />,
      label: 'Department',
      info: selectedPackage ? selectedPackage.pack_creator.department_name : '',
    },
    {
      icon: <TbFileDescription />,
      label: 'Document Price',
      info: selectedPackage ?  "€ " + selectedPackage.doc_price : 'NA',
    },
    {
      icon: <MdFormatListNumbered />,
      label: 'Num Of Products',
      info: selectedPackage ? selectedPackage.products.length + " products" : 'NA',
    },
    {
      icon: <FaEuroSign />,
      label: 'Creation fee',
      info: selectedPackage ? "€ " + selectedPackage.package_fulfillment_price : 'NA',
    },
    {
      icon: <FaEuroSign />,
      label: 'Creation fee status',
      info: selectedPackage && selectedPackage.creation_fee_status ? selectedPackage.creation_fee_status.charAt(0).toUpperCase() + selectedPackage.creation_fee_status.slice(1) : 'NA',
    },
    {
      icon: <IoMdRadioButtonOn />,
      label: 'Active Date',
      info: selectedPackage && selectedPackage.active_date? selectedPackage.active_date.substring(0, 10) : 'NA',
      condition: packageStatus === 'active',
    },
    {
      icon: <MdOutlineMotionPhotosOff />,
      label: 'Non Active Date',
      info: selectedPackage && selectedPackage.non_active_date? selectedPackage.non_active_date.substring(0, 10) : 'NA',
      condition: packageStatus === ' non-active',
    },
    {
      icon: <MdOutlineDateRange />,
      label: 'Paused Date',
      info: selectedPackage && selectedPackage.paused_date ? selectedPackage.paused_date.substring(0, 10) : 'NA',
      condition: packageStatus === ' paused',
    },
    {
      icon: <FaEuroSign />,
      label: 'Total Package Price',
      info: selectedPackage ? "€ " + selectedPackage.total_pack_price : 'NA',
    },
  ];



  return (
    <>
      <div className="m-auto w-[90%] text-center">
        <h2 className="mx-auto mt-11 font-sans text-2xl font-semibold text-custom-blue">
          Choose An Available Package To Edit
        </h2>
        <p className="mx-auto w-10/12 p-4 text-sm font-bold text-red-500 ">
          You can only Edit Package Stataus to Edit More ...<br/>
          <span className="text-custom-blue">Make a Copy</span> of an Existing
           package and customize it into a new one. 
           {/* <Link to="/next-tab">(Link to next Tab)</Link>. */}
        </p>

        <div className="mx-auto mb-12 flex w-[90%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
          <Select
            options={options}
            styles={customStyles}
            className="m-auto w-full"
            placeholder="Choose ..."
            onChange={handleProductSelect}
            value={selectedPackageOption}
          />

          {showEditForm ? null : (
            <button
              onClick={handleEditForm}
              disabled={!selectedPackage}
              className={`${selectedPackage ? 'text-md rounded-lg bg-custom-blue px-28 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue' : 'text-md cursor-not-allowed rounded-lg bg-gray-300 px-28 py-1 font-semibold text-gray-500'} `}
            >
              Edit
            </button>
          )}
        </div>
      </div>

      {showEditForm && (
        <>
          { failureMessage ? 
            <p className="mx-auto text-center w-10/12 text-sm font-bold text-red-400 ">
                Some Thing went wrong, Please Try again!
              </p>
              : null}
          {/** Package Information */}
          <div className="mx-auto flex w-[90%] flex-col justify-between py-6  md:flex-row ">
            <div className="mb-4 w-full rounded-3xl p-4 shadow-lg md:mb-0 md:w-[48%]">
            <h2 className="mx-auto my-3 text-center text-lg text-custom-blue font-bold">
                {selectedPackage ? selectedPackage.package_name : ''}
              </h2>
              <h2 className="mx-auto my-3 text-center text-lg font-semibold">
                Package Info.
              </h2>

              <div className="mb-2 flex flex-col gap-2 sm:flex-row sm:items-center">
                <span className="text-xl text-custom-green">
                  <GrStatusGood />
                </span>
                <label className="label">Package Status</label>
                <div className="flex flex-grow items-center justify-between">
                  <input
                    className="h-10 w-[50%] cursor-not-allowed rounded-lg border border-stone-200 p-2 text-xs text-gray-500 transition-all duration-300 placeholder:text-stone-400  focus:outline-none"
                    value={statusLabel}
                    disabled
                    name="dsm_status"
                  />
                  <div className="ml-2 w-[50%]">
                    <Select
                      options={status}
                      className="w-full text-xs"
                      onChange={(selectedOption) => {
                        setPackStatus(selectedOption.value);
                        setStatusLabel(selectedOption.label);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <h2 className="mx-auto mt-5 text-center text-lg font-semibold">
                General Information
              </h2>

              <table className="w-full table-auto">
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <th
                        className="bg-teal-100-accent w-1/4 py-2 text-left"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <div className="flex items-center">
                          <span className="text-base font-bold text-custom-green">
                            {row.icon}
                          </span>
                          <span className="ml-3 text-sm font-semibold">
                            {row.label}
                          </span>
                        </div>
                      </th>
                      <td className="w-4/12 border-b py-2 text-center">
                        <span className="ml-2 text-sm font-semibold text-stone-600">
                          {row.info}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            

            {/** Package Products Listing... */}
            <div className="h-[620px] w-full overflow-y-auto rounded-3xl p-4 shadow-lg md:w-[48%]">
              <h2 className="mx-auto my-3 text-center text-lg font-semibold">
                Package Products
              </h2>
              <ul>
                {productNames.length > 0
                  ? productNames.map((packageProduct, index) => (
                    <li key={index} className="mx-auto">
                    {/** Product Card */}
                    <div className="hover my-2 w-full flex-col items-center justify-center overflow-hidden rounded border bg-white p-1 shadow">
                    <div className="flex h-full w-full flex-row items-center justify-between">
                          <div className="mr-2 flex w-2/5 h-[100px]">
                            <img
                              src={imgPaths[packageProduct.product.assortimed_pid][0]}
                              alt="Product"
                              className="w-full flex-grow object-cover"
                            />
                          </div>
                          <div className="mb-3 mr-2 flex w-3/5 flex-col items-center">
                            <p className=" my-3 text-center text-sm font-bold text-custom-blue">
                              {packageProduct.product.name}
                            </p>
                            <div className="flex w-full justify-around text-xs font-semibold">
                              <div className="flex items-center rounded-md bg-[#f1f1f1] text-sm font-semibold">
                                {/* <button className="ml-3 mr-1 p-1 text-2xl text-custom-blue" onClick={() => handleDecrement(packageProduct)}>{' '}-{' '}</button> */}
                                <span className="mx-1 p-1">{packageProduct.quantity ? packageProduct.quantity + " Items": 'NA'}</span>
                                {/* <button className="ml-1 mr-3 p-1 text-xl text-custom-blue" onClick={() => handleIncrement(packageProduct)}>{' '}+{' '}</button> */}
                              </div>

                              <div className="flex items-center justify-center">
                                <h4 className="mr-2 text-sm font-bold text-custom-blue">
                                   {packageProduct.price ? "€ " + packageProduct.price : 'NA'}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    {/** End Product Card */}
                  </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>

          {/** Save or Cancel Changes */}
          <div className="mx-auto my-12 flex  w-full flex-col justify-between md:w-[60%] md:flex-row">
            <button
              onClick={handleSaveForm}
              className="text-md m-auto my-2  rounded-lg bg-custom-blue px-28 py-2 font-semibold text-white hover:bg-blue-700 "
            >
              Save Changes
            </button>
            <button
              onClick={handleCancel}
              className="text-md m-auto my-2 rounded-lg bg-amber-500 px-28 py-2 font-semibold text-white hover:bg-amber-400"
            >
              Cancel
            </button>
          </div>
        </>
      )}

      { successMessage ? 
          <p className="mx-auto text-center w-10/12 p-4 text-base mt-4 font-bold text-custom-blue ">
              You have successfully updated the status of your Package!
            </p>
        : null
      }
    </>
  );
}
