import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import { Row, Col } from 'react-grid-system';
import Pagination from 'react-js-pagination';
import { toast, ToastContainer } from 'react-toastify';

// Icon Imports
import { IoMdSearch } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import PopupReviewDsm from './PopupReviewDsm';

// Local Imports
import styles from '../../components/UShop/UserShopSlider.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '2px solid #2849ad' : '2px solid #d1d5db',
    borderRadius: '4px',
    ':hover': {
      border: '2px solid #d1d5db',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#07b1ab' : provided.backgroundColor,
    ':hover': {
      backgroundColor: '#07b1ab60',
    },
    ':first-child': {
      backgroundColor: '#07b1ab60',
    },
  }),
};

export default function CopyDsm({ dsmList }) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedDsm, setSelectedDsm] = useState(null);
  const [selectedDsmOption, setSelectedDsmOption] = useState(null);
  const [productNames, setProductNames] = useState([]);

  const handleEditForm = () => {
    if (selectedDsm) {
      setShowEditForm(!showEditForm);
    }
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  // Products Hangling Section (MiniShop)
  const [searchQuery, setSearchQuery] = useState('');
  const storedProducts = JSON.parse(localStorage.getItem('products')) || [];

  const filteredProducts = storedProducts.filter(
    (product) =>
      product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const imgPaths = JSON.parse(localStorage.getItem('imgPaths'));

  const uniqueCategories = [
    ...new Set(storedProducts.map((product) => product.category)),
  ];
  const uniqueSubCategory1 = [
    ...new Set(storedProducts.map((product) => product.sub_category_1)),
  ];
  const uniqueSubCategory2 = [
    ...new Set(storedProducts.map((product) => product.sub_category_2)),
  ];
  const uniqueBrand = [
    ...new Set(storedProducts.map((product) => product.brand)),
  ];

  const options = [
    { value: 'All', label: 'All' },
    ...uniqueCategories.map((item) => ({ value: item, label: item })),
  ];

  const [activeItem, setActiveItem] = useState('All');

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const [addedProducts, setAddedProducts] = useState([]);
  const handleAddProduct = (product) => {
    const existingProduct = addedProducts.find((p) => p.value === product.value);
    if (existingProduct) {
      //console.log('Product exists, triggering toast');
      showFail('This product already exists!');
    } else {
      //console.log('Adding new product');
      setAddedProducts([...addedProducts, { ...product}]);
    }
  };

  // Handling Searching in Search Box by product name
  const searchedProduct =
    searchQuery.length > 0
      ? storedProducts.filter((product) =>
          `${product.name}`.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : storedProducts;
  
  // Handling Deleting item from the addedProducts List
  const handleDeleteProduct = (product) => {
    setAddedProducts(addedProducts.filter((p) => p !== product));
  };

  // Handling fourm Visibility Change
  const [showForm, setShowForm] = useState(false);
  const handleOpenForm = () => {
    setShowForm(true);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };

  // refresh component to udate the products in MiniShop
  useEffect(() => {}, [searchQuery, filteredProducts]);
  const displayedProducts = filteredProducts;

  // DSM User Handling Section
  // const user = JSON.parse(localStorage.getItem('user'));
  // const user_dsm = user.dsm_data;
  const user_dsm = dsmList ? dsmList : [];

  const handleProductSelect = (selectedDsmOption) => {
    setSelectedDsmOption(selectedDsmOption);
    const dsmData = user_dsm.find(
      (dsm) => dsm.dsm_name === selectedDsmOption.value,
    );
    setSelectedDsm(dsmData);

    const products =
      dsmData && dsmData.products
        ? dsmData.products.map((dsmProduct) => {
          const foundProduct = storedProducts.find(product => dsmProduct.product === product.id);
          return {
              product: foundProduct,
              value: dsmProduct.id,
              label: dsmProduct.id,
              lower_limit: dsmProduct.lower_spec_stock,
              upper_limit: dsmProduct.higher_spec_stock,
              status: dsmProduct.product_status,
            };
          })
        : [];

    const dsmProducts =
    dsmData && dsmData.products
      ? dsmData.products.map((dsmProduct) => {
          const foundProduct = storedProducts.find(product => dsmProduct.product === product.id);
          console.log("DSM Product:", dsmProduct);
          console.log("Found Product:", foundProduct);
          return {
            id: dsmProduct.product,
            category: foundProduct.category,
            name: foundProduct.name,
            sales_price_c1: foundProduct.sales_price_c1,
            photo: imgPaths[foundProduct.assortimed_pid][0],
            label: foundProduct.name,
            value: foundProduct.id,
            // updatable dsmProducts fields
            minQuantity: dsmProduct.lower_spec_stock,
            maxQuantity: dsmProduct.upper_spec_stock,
            status: dsmProduct.product_status,
          };
        })
      : [];
    setProductNames(products);
    setAddedProducts(dsmProducts);
    console.log("Added DSM Products This one: ");
    console.log(dsmProducts);
  };

  const dsmOptions = user_dsm.map((dsm) => ({
    value: dsm.dsm_name,
    label: dsm.dsm_name,
  }));

  const status = [
    { value: 'active', label: 'Active' },
    { value: 'non_active', label: 'Non Active' },
    { value: 'paused', label: 'Paused' },
  ];


  const handleMaxQuantityChange = (value, product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.value === product.value ? { ...p, maxQuantity: value } : p,
      ),
    );
  };

  const handleMinQuantityChange = (value, product) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.value === product.value ? { ...p, minQuantity: value } : p,
      ),
    );
  };
  const handleStatusChange = (selectedValue, product) => {
    if (selectedValue) {
      setAddedProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.value === product.value ? { ...p, status: selectedValue } : p,
        ),
      );
    } else {
      setAddedProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.value === product.value ? { ...p, status: 'active' } : p,
        ),
      );
    }
  };



  //Pagination Controls
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 6;
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, searchedProduct.length);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };


  // Showing Sucess Message after clicing on the Proceed to Review button
  const showToast = (message) => {
      toast.success(message, {
        autoClose: 3000,
      });
    };
  const showFail = (message) => {
      toast.error(message, {
        autoClose: 3000,
      });
    };
  
  // Handling post sucess
  const handleCreationSucess = () => {
      setAddedProducts([]);
    }

  return (
    <>
      {!showEditForm && (
        <div className="m-auto my-auto w-[90%] text-center">
          <h2 className="mx-auto mt-11  font-sans text-2xl font-semibold text-custom-blue">
            Choose a DSM to Copy
          </h2>
          <p className="mx-auto w-8/12 p-4 text-sm font-bold text-red-500 ">
            Copying a DSM will allow you to create new DSM out of your existing
            DSM with any modifications you require to it.
          </p>

          <div className="mx-auto mb-12 flex w-[90%] flex-col justify-around gap-14 sm:gap-6 lg:flex-row">
            <Select
              options={dsmOptions}
              styles={customStyles}
              className="m-auto w-full"
              placeholder="Choose ..."
              onChange={handleProductSelect}
              value={selectedDsmOption}
            />
            {showEditForm ? null : (
              <button
                onClick={handleEditForm}
                disabled={!selectedDsm}
                className={`${selectedDsm ? 'text-md whitespace-nowrap rounded-lg bg-custom-blue px-20 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue' : 'text-md cursor-not-allowed whitespace-nowrap rounded-lg bg-gray-300 px-28 py-1 font-semibold text-gray-500'} `}
              >
                Start Copying ...
              </button>
            )}
          </div>
        </div>
      )}

      {/**Title after choosing a DSM */}
      <ToastContainer className="left-[40%]" />
      {showEditForm && (
        <div className="mx-auto mb-8 mt-8 flex w-[90%] items-center rounded p-2 text-center shadow">
          <div className="mx-auto flex w-2/3 flex-col items-center justify-center">
            <h2 className="font-sans text-2xl font-semibold text-black">
              {selectedDsm ? selectedDsm.dsm_name : ''}
            </h2>
            <p className="mt-2 p-2 text-sm font-bold text-red-500">
              Create new DSM by modifying
            </p>
          </div>

          <button
            onClick={handleCancel}
            className="text-md m-auto my-2 rounded-lg bg-amber-500 px-20 py-2 font-semibold text-white hover:bg-amber-400"
          >
            Cancel
          </button>
        </div>
      )}

      {showEditForm && (
        <div className="m-auto text-center shadow-lg">
          {/** Shop Page */}
          {/**Filtration section Orders Focus */}
          <div className="w-full border-b-2 border-gray-200 bg-white p-2">
            {/* On small screens, display the select dropdown */}
            <div className="block lg:hidden">
              <Select
                options={options}
                value={{ value: activeItem, label: activeItem }}
                onChange={(selectedOption) =>
                  handleItemClick(selectedOption.value)
                }
              />
            </div>

            {/* On larger screens, display the list view */}
            <ul className="text-md flex hidden w-full font-bold lg:flex">
              <li key="all" className="flex-1">
                <a
                  className={`block rounded border-b-2 border-transparent px-4 py-1 text-center 
          ${activeItem === 'All' ? 'font-bold text-gray-700' : 'text-gray-400'} 
           hover:font-bold hover:text-gray-700`}
                  onClick={() => handleItemClick('All')}
                  href="#"
                >
                  All
                </a>
              </li>
              {uniqueCategories.map((item, index) => (
                <li key={index} className="flex-1">
                  <a
                    to="#"
                    className={`block cursor-pointer rounded border-b-2 border-transparent px-4 py-1 text-center
          ${activeItem === item ? 'font-bold text-gray-700' : 'text-gray-400'} 
          hover:font-bold hover:text-gray-700`}
                    onClick={() => handleItemClick(item)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/**Filtration section Apply filter button */}
          <div className="flex w-full flex-wrap items-center justify-between border-b-2 border-gray-300 bg-white p-3 shadow-md">
            <div className="flex w-full justify-around md:w-[46%]">
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
                  <option value="">Sub Category 1</option>
                  {uniqueSubCategory1.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <select className="w-full rounded border bg-gray-200 p-1 px-2 focus:outline-none">
                  <option value="">Sub Category 2</option>
                  {uniqueSubCategory2.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex w-full justify-around md:w-[44%]">
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <select className="w-full rounded border bg-gray-200 p-1 px-2 outline-none">
                  <option value="">Brand</option>
                  {uniqueBrand.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="mb-2 w-full flex-1 px-2 md:mb-0">
                <input
                  type="search"
                  aria-label="Search"
                  className="form-control me-2 w-full rounded border bg-gray-200 p-1  px-2 outline-none"
                  placeholder="Search Product"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-2 flex w-full justify-center px-2 md:mb-0 md:w-auto">
              <button className="text-md w-full rounded-lg bg-custom-blue px-6 py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue sm:w-auto">
                Filter
              </button>
            </div>
          </div>

          {/* Side Choosen Product List */}
          <div className="flex flex-col sm:flex sm:flex-row">
            <aside
              className="sm:md-2 mb-6 grid w-full grid-rows-3 sm:w-[36%]"
              style={{ gridTemplateRows: '84px 670px 50px' }}
            >
              <div>
                <div className="bg-[#4ebfc4] px-2 py-3 text-center text-base font-bold text-white">
                  List of DSM Items/Products
                </div>
                <div className="bg-gray-100 p-2 text-center text-xs font-semibold text-neutral-400/75">
                  <p>Click + to add a product</p>
                </div>
              </div>

              {/** Listing Product Cards */}
              {addedProducts.length > 0 ? (
                <div className="m-1 w-full overflow-y-auto">
                  <ul>
                    {addedProducts.map((dsmProduct, index) => (
                      <li key={index} className="mt-6 flex justify-center">
                        {/* <span
                        className="absolute right-2 top-2 cursor-pointer"
                        onClick={() => handleDeleteProduct(product)}
                      >
                        <RiDeleteBin5Line className="text-red-500 " />
                      </span> */}
                        <div className="hover m-1 w-[90%] flex-col items-center justify-center overflow-hidden rounded border bg-white p-1 shadow">
                          <div className="my-2 flex h-full w-full flex-row items-center justify-between">
                            <span
                              className="absolute right-2 top-2 cursor-pointer bg-white"
                              onClick={() => handleDeleteProduct(dsmProduct)}
                            >
                              <RiDeleteBin5Line className="text-red-500 " />
                            </span>
                            <div className="mr-2 flex w-2/5">
                              <img
                                src={dsmProduct.photo}
                                alt="Product"
                                className="w-full flex-grow object-cover"
                              />
                            </div>
                            <div className="mr-2 flex w-full flex-col items-center">
                              <p className="mb-2 text-center text-sm font-bold text-custom-blue">
                                {dsmProduct.name}
                              </p>
                              <div className="flex flex-row items-center justify-between text-xs font-semibold">
                                <span>Stock Limit: </span>
                                <span className="text-amber-600">from</span>
                                <input
                                  className="w-[15%] rounded-lg border border-stone-200 p-1 text-xs transition-all duration-300 placeholder:text-stone-600  focus:outline-none"
                                  name="lower_stock_limit"
                                  placeholder={dsmProduct.minQuantity}
                                  onChange={(e) =>
                                    handleMinQuantityChange(Number(e.target.value), dsmProduct)}
                                />
                                <span className="text-amber-600">to</span>
                                <input
                                  className="w-[15%] rounded-lg border border-stone-200 p-1 text-xs transition-all duration-300 placeholder:text-stone-600  focus:outline-none"
                                  name="higher_stock_limit"
                                  placeholder={dsmProduct.maxQuantity}
                                  onChange={(e) =>
                                    handleMaxQuantityChange(Number(e.target.value), dsmProduct)
                                  }
                                />
                                <h4 className="mr-2 text-sm font-bold text-custom-blue">
                                  € {dsmProduct.price}
                                </h4>
                              </div>
                              {/**Line 2: Product Status */}
                              <div className="mb-2 mt-2 flex flex-row items-center justify-between text-xs font-semibold">
                                <span>Product Status: </span>
                                <p className=" cursor-not-allowed rounded-lg border border-stone-200 p-2 text-xs font-semibold text-gray-800 transition-all  duration-300 focus:outline-none">
                                  {dsmProduct.status
                                    ? dsmProduct.status === 'active'
                                      ? 'Active'
                                      : dsmProduct.status === 'paused'
                                        ? 'Paused'
                                        : 'Non-Active'
                                    : ''}
                                </p>
                                <div className="ml-2">
                                  <select
                                    className="w-full text-xs"
                                    onChange={(event) => {
                                      handleStatusChange(event.target.value, dsmProduct);
                                    }}
                                  >
                                    <option value="all">Select...</option>
                                    {status.map((option, index) => (
                                      <option key={index} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="m-auto text-gray-400">
                  <div className="m-auto flex h-20 w-20 items-center  justify-center rounded-full bg-gray-200 ">
                    <BsSearch className=" text-4xl " />
                  </div>
                </div>
              )}

              <div className="mt-2 flex w-full justify-center">
                <button
                  onClick={handleOpenForm}
                  className="text-md w-full rounded-lg bg-custom-blue py-1 font-semibold text-white hover:bg-blue-700 hover:text-custom-blue"
                >
                  <IoMdSearch className="mr-3 inline-block h-6 w-6 align-middle" />
                  Proceed to Review
                </button>
                {showForm && (
                  <PopupReviewDsm
                    onClose={handleCloseForm}
                    addedProducts={addedProducts}
                    dsmData={selectedDsm}
                    showToast={showToast}
                    showFail={showFail}
                  />
                )}
              </div>
            </aside>

            {/* MiniShop (Products & Packages) */}
            <div className="w-full overflow-hidden bg-custom-dsm sm:w-[64%]">
              <div className="h-20 w-full bg-[#d1eeee]"></div>
              <Row className="mb-8 mt-4">
                {Array.isArray(storedProducts) && storedProducts.length > 0 ? (
                  searchedProduct
                    .slice(startIndex, endIndex)
                    .map((card, index) => (
                      <Col lg={4} md={6} sm={8} xs={12} key={index}>
                        <Card key={index} className={styles.card_body}>
                          <div className="h-1/2 w-full">
                            <Card.Img
                              variant="top"
                              src={imgPaths[card.assortimed_pid][0]}
                              className="h-full w-full object-cover"
                            />
                          </div>
                          <Card.Body className="flex flex-grow flex-col justify-between p-4">
                            <div>
                              <Card.Title className="text-base font-bold">
                                {card.category}
                              </Card.Title>
                              <Card.Text className="text-sm font-semibold">
                                {card.name}
                              </Card.Text>
                            </div>
                            <div className="flex cursor-pointer justify-between">
                              <h4 className="mr-2 font-bold text-custom-blue">
                                € {card.sales_price_c1}
                              </h4>
                              <MdAdd
                                className="right-0 rounded-full bg-custom-blue p-1 text-2xl text-white"
                                onClick={() =>
                                  handleAddProduct({
                                    photo: imgPaths[card.assortimed_pid][0],

                                    price: card.sales_price_c1,
                                    value: card.id,
                                    label: card.name,
                                    name: card.name,
                                    category: card.category,
                                    id: card.id,
                                  })
                                }
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                ) : (
                  <p className="card-title">No products found.</p>
                )}
              </Row>
              {/* Pagination */}
              <Pagination
                activePage={activePage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={storedProducts.length}
                pageRangeDisplayed={4}
                onChange={handlePageChange}
                style={{ marginTop: '20px' }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
